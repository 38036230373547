import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

const addProtocolAndWwwToUrl = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = `https://${url}`;
  }
  if (!/^(https?:\/\/)?www\./i.test(url)) {
    url = url.replace(/^https?:\/\//i, "$&www.");
  }
  return url;
};

const addProtocolToUrl = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const WebsiteIframe = ({ websiteUrl }) => {
  const [urlForIframe, setUrlForIframe] = useState("");
  const [iframeError, setIframeError] = useState(false);

  useEffect(() => {
    if (!websiteUrl) {
      return;
    }

    const fetchWebsiteContent = async () => {
      try {
        let url = websiteUrl;

        let originalUrlSupportsWww;
        try {
          const response = await fetch(addProtocolAndWwwToUrl(websiteUrl), {
            method: "HEAD",
          });
          originalUrlSupportsWww = response?.ok || false;
        } catch {
          // Ignoring, we're good
        }

        if (originalUrlSupportsWww) {
          url = addProtocolAndWwwToUrl(websiteUrl);
        } else {
          url = addProtocolToUrl(websiteUrl);
        }

        // Fetch the website content
        const websiteResponse = await fetch(url);
        if (!websiteResponse.ok) {
          throw Error("Website couldn't be shown in iframe");
        }

        setUrlForIframe(url);
      } catch (error) {
        setIframeError(true);
      }
    };

    fetchWebsiteContent();
  }, [websiteUrl]);

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      {iframeError ? (
        <div className="h-full w-full bg-gray-200 flex items-center justify-center flex-col gap-3">
          <p className="text-gray-600 text-xl">
            Your Website cannot be loaded in the iframe.
          </p>
          <p className="text-gray-600 text-xl">
            However, you can still try your Chatbot! 😉👇
          </p>
        </div>
      ) : (
        <iframe
          src={urlForIframe}
          title="Website iframe"
          className="h-full w-full"
          sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
        />
      )}
    </div>
  );
};

const WidgetDemo = () => {
  const [website, setWebsite] = useState("");
  const [botId, setBotId] = useState("");

  useEffect(() => {
    // Get the URL query parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Extract the 'website' and 'botId' values from the query parameters
    const websiteParam = queryParams.get("website");
    const botIdParam = queryParams.get("botId");

    // Update the state with the extracted values
    setWebsite(websiteParam || "");
    setBotId(botIdParam || "");
  }, []);

  return (
    <>
      <Helmet>
        {botId && (
          <script
            async
            src="https://www.vivochat.ai/dist/widget.js"
            vivochat-bot-id={botId}
            vivochat-bot-container=".widget-demo-container"
          />
        )}
      </Helmet>
      <div className="flex items-center justify-center h-screen w-screen widget-demo-container">
        <WebsiteIframe websiteUrl={website} />
      </div>
    </>
  );
};

export default WidgetDemo;
