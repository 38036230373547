export const UserMessage = ({ children, botSettingsForUse }) => {
  return (
    <div className="flex flex-col space-y-1.5 self-end items-end max-w-[80%]">
      <div
        className="inline-flex flex-col gap-2 font-normal rounded-xl px-4 py-3 text-white rounded-tr-sm"
        style={{ background: botSettingsForUse?.brandColor }}
      >
        {children}
      </div>
    </div>
  );
};
