import React, { useState } from "react";

import Illustration from "../images/pricing-illustration.svg";

import PricingGrid from "./PricingGrid";

function Pricing() {
  return (
    <section className="relative">
      {/* Illustration */}
      <div
        className="lg:block absolute bottom-0 left-1/2 -translate-x-1/2 -mb-24 pointer-events-none -z-10"
        aria-hidden="true"
      >
        <img
          src={Illustration}
          className="max-w-none"
          width="1440"
          height="440"
          alt="Pricing Illustration"
        />
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 pb-12 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-hkgrotesk mb-4">
              Let's find the right plan for you
            </h2>
            <p className="text-xl text-slate-500">
              Try it for free and upgrade when you need more
            </p>
          </div>
          {/* Pricing tables */}
          <PricingGrid />
        </div>
      </div>
    </section>
  );
}

export default Pricing;
