import React from "react";

import { Button } from "flowbite-react";

const GradientOutlineButton = ({ websiteURL, botId, children, ...props }) => {
  return (
    <Button gradientDuoTone="purpleToBlue" size="lg" outline {...props}>
      <p className="font-bold">{children}</p>
    </Button>
  );
};

export default GradientOutlineButton;
