import { useFormik } from "formik";
import { Messages } from "primereact/messages";
import React, { useContext, useEffect, useRef, useState } from "react";

import { Button, Label, TextInput } from "flowbite-react";
import { toast } from "react-toastify";
import Context from "../../../../context";

import { addSitemap } from "../../../../clients/BotClient";

import useFirestoreRealTimeData from "../../../../utils/hooks/useFirestoreRealTimeData";

const TrainBySitemap = ({ tabActiveIndex }) => {
  const { user } = useContext(Context);
  const [sitemapUploadLoadingStatus, setSitemapUploadLoadingStatus] =
    useState("initial");
  const msgs = useRef(null);

  const firestoreUserUpToDateData = useFirestoreRealTimeData("users", user.uid);

  useEffect(() => {
    if (!msgs?.current || !firestoreUserUpToDateData) {
      return;
    }

    if (tabActiveIndex !== 0) {
      return;
    }

    msgs.current.clear();

    if (
      !firestoreUserUpToDateData?.sitemap_progress_status ||
      firestoreUserUpToDateData?.sitemap_progress_status === "initial"
    ) {
      msgs.current?.show({
        sticky: true,
        severity: "info",
        summary: "Add Sitemap URL and start training your ChatBot on it!",
        closable: false,
      });
    }

    if (firestoreUserUpToDateData?.sitemap_progress_status === "processing") {
      msgs.current?.show({
        sticky: true,
        severity: "warn",
        summary: "Training ChatBot on your Sitemap is In Progress.",
        detail: "It will take some time",
        closable: false,
      });
    }

    if (firestoreUserUpToDateData?.sitemap_progress_status === "success") {
      msgs.current?.show({
        sticky: true,
        severity: "success",
        summary: "Training on your Sitemap Successfully finished!",
        closable: false,
      });
    }

    if (firestoreUserUpToDateData?.sitemap_progress_status === "error") {
      msgs.current?.show({
        sticky: true,
        severity: "error",
        summary: "There was a problem with training ChatBot on your Sitemap.",
        detail: "Check if it's setup correctly and try again.",
        closable: false,
      });
    }
  }, [msgs, tabActiveIndex, firestoreUserUpToDateData]);

  const { handleSubmit, values, handleChange } = useFormik({
    initialValues: {
      sitemapUrl: "",
    },
    enableReinitialize: true,
    onSubmit: async ({ sitemapUrl }) => {
      if (!user) {
        toast.error("Login pleas and train your Bot");
        return;
      }

      setSitemapUploadLoadingStatus("processing");
      try {
        await addSitemap({
          user_id: user.uid,
          sitemap: sitemapUrl,
        });
        setSitemapUploadLoadingStatus("success");
        toast.success(
          "Bot Training by Sitemap has started! It will take some time."
        );
      } catch (error) {
        setSitemapUploadLoadingStatus("error");
        toast.error(
          "Something went wrong, maybe the Sitemap URL is not correct."
        );
      }
    },
  });

  return (
    <>
      <div className="card flex w-full px-3 pb-6 min-h-[120px]">
        <Messages className="w-full" ref={msgs} />
      </div>
      <form
        className="flex flex-col gap-6 w-96 min-w-[250px]"
        onSubmit={handleSubmit}
      >
        <div className="space-y-3">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="sitemapUrl" value="Your Sitemap URL" />
            </div>
            <TextInput
              id="sitemapUrl"
              placeholder="www"
              required
              type="text"
              color="dark"
              value={values.sitemapUrl}
              onChange={handleChange}
            />
          </div>
        </div>
        <Button
          type="submit"
          isProcessing={sitemapUploadLoadingStatus === "processing"}
          disabled={
            firestoreUserUpToDateData?.sitemap_progress_status === "processing"
          }
          color="dark"
        >
          Train
        </Button>
      </form>
    </>
  );
};

export default TrainBySitemap;
