import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import { FileUploader } from "react-drag-drop-files";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import documentClient from "../../clients/DocumentClient";
import Spinner from "../ui/Spinner";
import { useContext } from "react";
import Context from "../../context";
import PageHeader from "../ui/PageHeader";
import PageFooter from "../ui/PageFooter";
import { Link } from "react-router-dom";

export default function Support(props) {
  return (
    <div>
      <div className="header__logo-full text-xl font-bold mt-3 ml-3">
        VivoChat
      </div>
      <div className="flex w-full mt-10 justify-center">
        <div>
          <div className={"text-center text-3xl"}>
            For any support please contact us on vivochat.ai@gmail.com
          </div>

          {/* <div className={'text-center mt-5'}>
                    <Link to="/dashboard/conversation">
                        To Dashboard <FontAwesomeIcon icon={faChevronRight} size='sm'/>
                    </Link>

                </div> */}
        </div>
      </div>
    </div>
  );
}
