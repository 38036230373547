import { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";

import { db } from "../../firebase";

// Custom hook to fetch real-time data from Firestore
const useFirestoreRealTimeData = (collection, document) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const docRef = doc(db, collection, document);

    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setData(docSnapshot.data());
      } else {
        setData(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [collection, document]);

  return data;
};

export default useFirestoreRealTimeData;
