import React, { useState } from "react";

import Illustration from "../images/features-illustration-02.svg";
import MessageHistory from "../images/features/message-history 1.png";
import Leads from "../images/features/leads 1.png";

function Features02() {
  const [category, setCategory] = useState("1");

  return (
    <section className="relative border-t border-slate-200">
      {/* Bg gradient: top */}
      <div
        className="absolute top-0 left-0 right-0 bg-gradient-to-b from-slate-300 to-transparent opacity-25 h-[25rem] pointer-events-none -z-10"
        aria-hidden="true"
      />
      {/* Illustration */}
      <div
        className="lg:block absolute top-0 left-1/2 -translate-x-1/2 mt-40 pointer-events-none -z-10"
        aria-hidden="true"
      >
        <img
          src={Illustration}
          className="max-w-none"
          width="1440"
          height="453"
          alt="Features 02 Illustration"
        />
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-hkgrotesk">
              Boost website engagement using AI ChatBot
            </h2>
          </div>
          {/* Box */}
          <div className="bg-slate-200 bg-opacity-60 rounded overflow-hidden">
            <div className="flex flex-col lg:flex-row items-end md:items-center lg:justify-between lg:space-x-20 py-3 md:pr-6">
              <div className="md:min-w-[28rem] p-6 lg:p-10">
                {/* Filters */}
                <div className="mb-6 lg:mb-8">
                  <div className="flex flex-wrap -m-1.5">
                    <button
                      className={`btn-sm px-3 py-1 shadow-sm rounded-full m-1.5 ${
                        category === "1"
                          ? "text-white bg-indigo-500"
                          : "text-slate-300 bg-slate-700 hover:bg-slate-600 border-slate-600"
                      }`}
                      onClick={() => setCategory("1")}
                    >
                      Chat history
                    </button>
                    <button
                      className={`btn-sm px-3 py-1 shadow-sm rounded-full m-1.5 ${
                        category === "2"
                          ? "text-white bg-indigo-500"
                          : "text-slate-300 bg-slate-700 hover:bg-slate-600 border-slate-600"
                      }`}
                      onClick={() => setCategory("2")}
                    >
                      Lead Generation
                    </button>
                  </div>
                </div>
                {/* Content */}
                <div>
                  <div className={`${category !== "1" && "hidden"}`}>
                    <h3 className="h3 font-hkgrotesk mb-2">See chat history</h3>
                    <div className="text-lg text-slate-500">
                      See chat history of your chatbot and analyse what your
                      website visitors ask and provide better service.
                    </div>
                  </div>
                  <div className={`${category !== "2" && "hidden"}`}>
                    <h3 className="h3 font-hkgrotesk mb-2">Collect Emails</h3>
                    <div className="text-lg text-slate-500">
                      Effortlessly gather emails from your website visitors with
                      the help of the Vivo Chat widget. Engage your audience
                      with interactive chat bot interactions, where they'll
                      receive a convenient email collection form. Allow your
                      visitors to simply type in their email and send it to you
                      in an instant.
                    </div>
                  </div>
                </div>
              </div>
              {category === "1" && (
                <img
                  src={MessageHistory}
                  className="md:max-w-none"
                  width="480"
                  height="414"
                  alt="Feature"
                />
              )}
              {category === "2" && (
                <img
                  src={Leads}
                  className="md:max-w-none"
                  width="480"
                  height="414"
                  alt="Feature"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features02;
