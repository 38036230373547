import React, { useEffect } from "react";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";

import logo from "./logo.svg";
import "./components/view/landingpage/css/style.css";
import "./App.css";
import "aos/dist/aos.css";

import AOS from "aos";

import Sidebar from "./components/view/Sidebar";
import Uploader from "./components/ui/Uploader";
import Conversation from "./components/view/Conversation";
import { Redirect, Route, Routes, useLocation } from "react-router-dom";
// import Login from "./components/view/Login";
import Dashboard from "./components/pages/Dashboard";
import WidgetDemo from "./components/pages/WidgetDemo";
import { useState } from "react";
import Context from "./context";
import Landing from "./components/view/Landing";
import Home from "./components/view/landingpage/pages/Home";
import Login from "./components/view/landingpage/pages/SignIn";
import Signup from "./components/view/landingpage/pages/SignUp";
import Pricing from "./components/view/Pricing";
import Privacy from "./components/view/Privacy";
import Terms from "./components/view/Terms";
import Support from "./components/view/Support";
import UserSubscription from "./components/pages/Profile/parts/UserSubscription";
import Profile from "./components/pages/Profile/Profile";
import ThankYou from "./components/view/ThankYou";
import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

const TRACKING_ID = "G-JT3MDDM33T";
ReactGA.initialize(TRACKING_ID);
ReactGA.send({ hitType: "pageview", page: "/home", title: "Visit" });

mixpanel.init("44e4eb252ca501daa72f052a9ef19af0");

mixpanel.track("Visit", {
  source: "Web App",
});

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 500,
      easing: "ease-out-cubic",
    });
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change
  //   <Route path="/pricing" element={<Pricing />} />
  return (
    <PrimeReactProvider>
      <ToastContainer />
      <Routes>
        {/* <Route path="/" element={<Landing />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/support" element={<Support />} />
        <Route path="/terms-of-service" element={<Terms />} />
        {/* <Route path="/dashboard/sharing/:sharingKey" element={<Dashboard />} /> */}
        <Route path="/dashboard*" element={<Dashboard />} />
        <Route path="/widget-demo" element={<WidgetDemo />} />
        {/* <Route path="/profile" element={<Profile />} /> */}
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>
    </PrimeReactProvider>
  );
}

export default App;
