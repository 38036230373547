import React from "react";

export const BouncingLoader = () => {
  const dotClassName = "w-3 h-3 mb-2 mt-5 mx-1 bg-gray-500 rounded-full";

  return (
    <div className="flex justify-center">
      <div className={`${dotClassName} animate-loader`} />
      <div
        className={`${dotClassName} animate-loader`}
        style={{ animationDelay: "0.2s" }}
      />
      <div
        className={`${dotClassName} animate-loader`}
        style={{ animationDelay: "0.4s" }}
      />
    </div>
  );
};
