import React from "react";

import { Link } from "react-router-dom";
import GradientOutlineButton from "../../../ui/buttons/GradientOutlineButton";

const PreviewButtonLink = ({ websiteURL, botId, children, ...props }) => {
  return (
    <GradientOutlineButton
      as={Link}
      href={`/widget-demo?website=${websiteURL}&botId=${botId}`}
      target="_blank"
      {...props}
    >
      {children}
    </GradientOutlineButton>
  );
};

export default PreviewButtonLink;
