import React from "react";
import Spinner from "./Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { Button } from "flowbite-react";

export default function CopyableText(props) {
  const linkStyle = {
    maxWidth: "90%",
  };

  function onTextCopied() {
    navigator.clipboard.writeText(props.text);
    props.onTextCopied();
  }

  return (
    <div className="flex items-center gap-3">
      <div
        style={linkStyle}
        className={
          "break-words flex-grow px-2 p-1 rounded border border-gray-300 bg-gray-200 text-gray-700"
        }
      >
        {props.text}
      </div>
      <Button
        onClick={onTextCopied}
        type="submit"
        gradientDuoTone="purpleToBlue"
        outline
        size="md"
      >
        <FontAwesomeIcon icon={faCopy} size="md" />
      </Button>
    </div>
  );
}
