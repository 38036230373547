import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

import { initializeApp } from "firebase/app";
import userClient from "./clients/UserClient";

// TODO: Replace the following with your app's Firebase project configuration

const firebaseConfig = {
  apiKey: "AIzaSyD-CsuR1rDtQ5EY3N5VJVCGcoxUfUDTfjg",
  authDomain: "vivo-chat-ai.firebaseapp.com",
  projectId: "vivo-chat-ai",
  storageBucket: "vivo-chat-ai.appspot.com",
  messagingSenderId: "1014638297734",
  appId: "1:1014638297734:web:7dc33e76dabdd75be24707",
  measurementId: "G-JT3MDDM33T",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    // The signed-in user info.
    const user = result.user;
    const idToken = await user.getIdToken();

    await userClient.login(idToken);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithEmail = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    const user = result.user;
    const idToken = await user.getIdToken();

    await userClient.login(idToken);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signUpWithEmail = async (email, password) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    const user = result.user;
    const idToken = await user.getIdToken();

    await sendEmailVerification(user);
    await userClient.login(idToken);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
  window.location.href = "/";
};

export { auth, db, signInWithGoogle, signInWithEmail, signUpWithEmail, logout };
