class SharingClient {
  async createSharingLink(authorizationToken) {
    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/create-sharing-link",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        },
      }
    );

    return await responseJson.json();
  }

  async deleteSharingLink(authorizationToken) {
    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/delete-sharing-link",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        },
      }
    );

    return await responseJson.json();
  }

  async getShare(authorizationToken) {
    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/get-share",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        },
      }
    );

    let json = await responseJson.json();

    return Object.assign(
      {
        is_hiding_logo: false,
        is_hiding_link: false,
      },
      json
    );
  }

  async updateSharingTitle(authorizationToken, title) {
    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/update-sharing-title",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        },
        body: JSON.stringify({ title: title }),
      }
    );

    return await responseJson.json();
  }

  async updateIsHidingLogo(authorizationToken, isHidingLogo) {
    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/update-hiding-logo",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        },
        body: JSON.stringify({ isHidingLogo: isHidingLogo }),
      }
    );

    return await responseJson.json();
  }

  async updateIsHidingLink(authorizationToken, isHidingLink) {
    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/update-hiding-link",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        },
        body: JSON.stringify({ isHidingLink: isHidingLink }),
      }
    );

    return await responseJson.json();
  }
}

const sharingClient = new SharingClient();
export default sharingClient;
