import React from "react";

function Faqs() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="pb-12 md:pb-20">
            <h2 className="h2 font-hkgrotesk">FAQs</h2>
          </div>
          {/* Columns */}
          <div className="md:flex md:space-x-12 space-y-8 md:space-y-0">
            {/* Column */}
            <div className="w-full md:w-1/2 space-y-8">
              {/* Item */}
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">
                  How do I set this up for my website?
                </h4>
                <p className="text-slate-500">
                  If you are facing any problem in embedding the chatbot then simply get in touch with us and we will setup it for you free of cost.
                  We also provide custom implementation that is suitable for your business and work with you on every step, no matter how small or large your
                  organization is.
                </p>
              </div>
              {/* Item */}
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">
                  Where is my data stored?
                </h4>
                <p className="text-slate-500">
                  We use public data from your website that is already available on Bing.
                </p>
              </div>
              {/* Item */}
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">
                  Do you provide custom solution?
                </h4>
                <p className="text-slate-500">
                  Yes, we provide custom solution based on customer's need. We also provide APIs as well. Get in touch with us to know more.
                </p>
              </div>
            </div>
            {/* Column */}
            <div className="w-full md:w-1/2 space-y-8">
              {/* Item */}
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">
                  How much does it cost?
                </h4>
                <p className="text-slate-500">
                  VivoChat provides free trial. You can subscribe to a paid plan if it meets your requirements. Our goal is to let every website harness the
                  power of ChatGPT, so we provide very
                  competitive pricing.
                </p>
              </div>
              {/* Item */}
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">
                  Does it support other languages?
                </h4>
                <p className="text-slate-500">
                  Yes, it supports over 95 languages. You can have your
                  chatbot answer question in over 95 languages.
                </p>
              </div>
              <div className="space-y-2">
                <h4 className="text-xl font-hkgrotesk font-medium">
                  Do you have an affiliate program?
                </h4>
                <p className="text-slate-500">
                  We do run an affiliate program. Please contact us to know more
                  details about it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
