class StripeClient {
  async checkout(authorizationToken, priceId) {
    const data = new FormData();
    data.append("priceId", priceId);

    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/create-checkout-session",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        },
        body: JSON.stringify({ priceId: priceId }),
      }
    );

    return await responseJson.json();
  }

  async startPortalSession(authorizationToken) {

    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/stripeportalsession",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        }
        // body: JSON.stringify({ bot_id: botId })
      }
    );

    return await responseJson.json();
  }

  async getSubscription(authorizationToken) {
    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/usersubscription",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer: " + authorizationToken,
        }
      }
    );

    const sub = await responseJson.json();
    return sub["subscription"];
  }
}

const stripeClient = new StripeClient();
export default stripeClient;
