export const getBot = async (payload) => {
  const responseJson = await fetch(process.env.REACT_APP_API_URL + "/get-bot", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const response = await responseJson.json();

  return response;
};

export const saveBot = async (payload) => {
  const responseJson = await fetch(
    process.env.REACT_APP_API_URL + "/create-bot",
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const response = await responseJson.json();

  return response;
};

export const saveBotLeadEmail = async (payload) => {
  const responseJson = await fetch(
    process.env.REACT_APP_API_URL + "/save-lead-email-bot",
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const response = await responseJson.json();

  return response;
};

export const addSitemap = async (payload) => {
  const responseJson = await fetch(
    process.env.REACT_APP_API_URL + "/add-sitemap",
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

  const response = await responseJson.json();

  return response;
};
