import { useState } from "react";

import { BotMessage } from "./BotMessage";
import { SendIcon } from "./SendIcon";

import { saveBotLeadEmail } from "../../../../clients/BotClient";

export const EmailFormMessage = ({
  botId,
  botSettingsForUse,
  isEmailFormShown,
  setIsEmailFormShown,
}) => {
  const [email, setEmail] = useState("");
  const [loadingStatus, setLoadingStatus] = useState("initial");
  const [successText, setSuccessText] = useState(
    "Email was added successfully!"
  );

  const handleLeadEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    const saveEmailData = await saveBotLeadEmail({
      bot_id: botId,
      email,
      country: data?.country_name,
      city: data?.city,
    });

    if (saveEmailData.status === 200) {
      setLoadingStatus("success");
    } else if (saveEmailData.status === 400) {
      setSuccessText("Email was already added, thank you!");
      setLoadingStatus("success");
    } else {
      setLoadingStatus("error");
    }
    setTimeout(() => setIsEmailFormShown(false), 5000);
  };

  if (!isEmailFormShown) {
    return <></>;
  }

  return (
    <BotMessage>
      {loadingStatus === "success" ? (
        <p className="text-green-500">{successText}</p>
      ) : (
        <form className="relative flex gap-4" onSubmit={handleLeadEmailSubmit}>
          <div className="space-y-1">
            <label for="email" className="text-gray-600 text-sm">
              Let us know how to contact you
            </label>
            <div className="flex gap-2 items-center">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="name@example.com"
                className="block w-[250px] text-gray-900 caret-blue-600 shadow-sm ring-1 ring-inset ring-gray-300 rounded-lg border-0 px-3 py-2 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                aria-label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loadingStatus === "processing"}
              />
              <button
                type="submit"
                className="p-2 rounded-full cursor-pointer hover:bg-white"
                disabled={loadingStatus === "processing"}
              >
                <SendIcon fill={botSettingsForUse?.brandColor} />
              </button>
            </div>
          </div>
        </form>
      )}
    </BotMessage>
  );
};
