class ConversationClient {
  async askGPT3(question, documentId) {
    const data = new FormData();
    data.append("documentId", documentId);
    data.append("question", question);

    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/askgpt3",
      {
        method: "POST",
        body: data,
      }
    );

    return await responseJson.json();
  }

  async askGPT3FromCollection(question, authenticationToken, questionIndex) {
    const data = new FormData();
    data.append("question", question);
    data.append("index", questionIndex);

    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/ask-from-collection",
      {
        method: "POST",
        body: data,
        headers: {
          Authorization: "Bearer: " + authenticationToken,
        },
      }
    );

    if (responseJson.status === 402) {
      return {
        data: {
          isBot: true,
          paymentRequired: true,
        },
      };
    }

    return await responseJson.json();
  }
  async getSummary(question, id, authenticationToken) {
    const data = new FormData();
    data.append("question", "Summarize this document.");
    data.append("document_id", id);

    const responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/get-summary",
      {
        method: "POST",
        body: data,
        headers: {
          Authorization: "Bearer: " + authenticationToken,
        },
      }
    );

    if (responseJson.status === 402) {
      return {
        data: {
          isBot: true,
          paymentRequired: true,
        },
      };
    }

    return await responseJson.json();
  }
  // async getResponseStream(question, authenticationToken, questionIndex) {
  //   const data = new FormData();
  //   data.append("question", "Summarize this document.");
  //   data.append("document_id", id);

  //   const responseJson = await fetch(
  //     process.env.REACT_APP_API_URL + "/get-summary",
  //     {
  //       method: "POST",
  //       body: data,
  //       headers: {
  //         Authorization: "Bearer: " + authenticationToken,
  //       },
  //     }
  //   );

  //   if (responseJson.status === 402) {
  //     return {
  //       data: {
  //         isBot: true,
  //         paymentRequired: true,
  //       },
  //     };
  //   }

  //   return await responseJson.json();
  // }
  async getDocumentConversation(id) {
    var data = new FormData();
    data.append("documentId", id);
    let init = {
      method: "POST",
      body: data,
    };

    let responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/get-document-conversations",
      init
    );

    let response = await responseJson.json();

    let messages = response.data.list.map((r) => ({
      message: r.message,
      isBot: r.is_bot,
    }));

    return messages.reverse();
  }

  async getUserConversation(authenticationToken) {
    var data = new FormData();
    //data.append("userId", id);

    let init = {
      method: "POST",
      body: data,
      headers: {
        Authorization: "Bearer: " + authenticationToken,
      },
    };

    let responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/get-collection-conversations",
      init
    );

    let response = await responseJson.json();

    let messages = response.data.list.map((r) => ({
      message: r.message,
      isBot: r.is_bot,
    }));

    return messages.reverse();
  }
}

const conversationRepository = new ConversationClient();
export default conversationRepository;
