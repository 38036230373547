import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLink,
  faHome,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMagnifyingGlass,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import conversationClient from "../../clients/ConversationClient";
import Spinner from "../ui/Spinner";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import Banner from "./Banner";
import Uploader from "../ui/Uploader";
import { useContext } from "react";
import Context from "../../context";
import ConversationInput from "../ui/ConversationInput";
import Logo from "../ui/Logo";
import sharingClient from "../../clients/SharingClient";
import { SSE } from "sse";
import mixpanel from "mixpanel-browser";

mixpanel.init("44e4eb252ca501daa72f052a9ef19af0");

export default function Conversation() {
  let { id } = useParams();

  let sampleConversation = {};
  sampleConversation[undefined] = [
    {
      message:
        "Can you explain the reasons for the decrease in our gross margin for the fiscal year?",
      isBot: false,
    },
    {
      message: {
        answer:
          "The decrease in gross margin for the fiscal year can be attributed to a few factors. Firstly, we experienced an increase in the cost of raw materials which impacted the cost of goods sold. Additionally, we also had to offer discounts and promotions in order to remain competitive in the market. These factors combined resulted in a decrease in our gross margin for the fiscal year",
        documents: [
          {
            page: 18,
            file: "Annual_Report.pdf",
            score: 0.92,
          },
        ],
      },
      isBot: true,
    },

    {
      message:
        "How did our international sales perform compared to domestic sales for the fiscal year?",
      isBot: false,
    },
    {
      message: {
        answer:
          "For the fiscal year, our international sales performed well and grew by XX%, compared to domestic sales which only grew by YY%. This can be attributed to the efforts we made to expand our presence in international markets, as well as a favorable exchange rate.",
        documents: [
          {
            page: 8,
            file: "International_Sales.pdf",
            score: 0.96,
          },
        ],
      },
      isBot: true,
    },

    {
      message:
        "Can you provide an overview of our cash flow for the fiscal year?",
      isBot: false,
    },
    {
      message: {
        answer:
          "Our cash flow for the fiscal year was strong. We had a positive cash flow from operating activities of $XX million, which was primarily driven by our profits. Additionally, our investing activities also generated a positive cash flow of $YY million through the sale of investments. Our financing activities also generated a positive cash flow of $ZZ million through the issuance of new debt. Overall, we ended the fiscal year with a cash balance of $AAA million.",
        documents: [
          {
            page: 12,
            file: "Annual_Report.pdf",
            score: 0.8,
          },
        ],
      },
      isBot: true,
    },
    {
      message:
        "Can you explain the impact of the new tax laws on our financial results for the fiscal year?",
      isBot: false,
    },
    {
      message: {
        answer:
          "The new tax laws had a significant impact on our financial results for the fiscal year. The decrease in the corporate tax rate resulted in a lower effective tax rate for the year, which led to an increase in our net income. However, the new laws also resulted in a one-time charge for the revaluation of our deferred tax assets and liabilities which had a negative impact on our results for the fiscal year.",
        documents: [
          {
            page: 15,
            file: "Annual_Report.pdf",
            score: 0.8,
          },
        ],
      },
      isBot: true,
    },
  ];

  sampleConversation = null;

  const [question, setQuestion] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  // const [user, isLoading, error] = useAuthState(auth);
  let {
    user,
    authenticationToken,
    msgloading,
    setMsgloading,
    msg,
    setMsg,
    isSharing,
    sharingTitle,
    isHidingLogo,
    isHidingLink,
  } = useContext(Context);
  // const [messages, setMessages] = useState(sampleConversation || {});
  // const [isSendingQuestion, setIsSendingMessage] = useState(false);

  const scrollRef = React.createRef();

  let [result, setResult] = useState("");
  let [answeringon, setansweringon] = useState(false);

  const resultRef = useRef();

  useEffect(() => {
    resultRef.current = result;
  }, [result]);

  useEffect(() => {
    async function fetchData() {
      if (sampleConversation || !authenticationToken) {
        return;
      }

      if (msg[id]) {
        return;
      }

      let newMessages = id
        ? await conversationClient.getDocumentConversation(id)
        : isSharing
        ? []
        : await conversationClient.getUserConversation(authenticationToken);
      setMsg({
        ...msg,
        [id]: newMessages,
      });

      console.log({
        ...msg,
        [id]: newMessages,
      });
    }
    fetchData();
  }, [authenticationToken, msg]);

  useEffect(() => {
    if (!msg[id] || !msg[id].length) {
      return;
    }

    if (!isSharing) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [msg]);

  async function regenerateResponse() {
    let q = getQuestionToRegenerate();
    let qI = questionIndex + 1;
    setQuestionIndex(qI);
    msg[id].pop();
    msg[id].pop();
    await sendQuestion(q, qI);
  }

  function getQuestionToRegenerate() {
    return msg[id] &&
      msg[id][msg[id].length - 2] &&
      !msg[id][msg[id].length - 2].isBot
      ? msg[id][msg[id].length - 2].message
      : " ";
  }

  async function sendQuestion(q, qI) {
    let questionToUse = q || question;
    setMsgloading(true);

    if (msgloading) {
      return;
    }

    if (!qI) {
      setQuestionIndex(3);
    }

    qI = qI || 3;

    let withQuestion = [
      ...msg[id],
      {
        message: questionToUse,
        isBot: false,
      },
    ];

    setMsg({
      ...msg,
      [id]: withQuestion,
    });

    if (!isSharing) {
      setQuestion("", true);
    }
    try {
      // const fdata = new FormData();
      // fdata.append("question", questionToUse);
      // fdata.append("index", qI);
      const fdata = {
        question: questionToUse,
        index: qI,
      };
      console.log(fdata);

      if (questionToUse !== "") {
        setResult("");
        let url = process.env.REACT_APP_API_URL + "/ask-from-collection-stream";
        console.log(url);

        let source = new SSE(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer: " + authenticationToken,
          },
          method: "POST",
          payload: JSON.stringify(fdata),
        });

        source.addEventListener("message", (e) => {
          console.log("streaming started");

          if (e.data != "[DONE]") {
            // console.log(e);
            let payload = JSON.parse(e.data);
            let text = payload.data.answer;
            // console.log(text);
            if (text != "\n") {
              // console.log("Text: " + text);
              resultRef.current = resultRef.current + text;
              // console.log("ResultRef.current: " + resultRef.current);
              setResult(resultRef.current);
              let prevMessages = isSharing ? [] : withQuestion;

              setMsg({
                ...msg,
                [id]: [
                  ...prevMessages,
                  {
                    message: {
                      answer: id
                        ? payload.data.answer
                        : payload.data.answer || payload.data.answer,
                      documents: id ? [] : payload.data.documents,
                    },
                    paymentRequired: payload.data.paymentRequired,
                    isBot: true,
                  },
                ],
              });
            }
          } else {
            source.close();
          }
        });

        source.addEventListener("readystatechange", (e) => {
          if (e.readyState >= 2) {
            setMsgloading(false);
          }
        });

        source.stream();
      } else {
        alert("Please insert a prompt!");
      }
    } catch (e) {
      if (e.type === "payment") {
        if (
          window.confirm(
            "You have run out of free credits, please create a subscription to continue"
          )
        ) {
          window.location.href = "/profile";
        }

        setMsgloading(false);
        return;
      }
    }
    // let response;
    // try {
    //   response = id
    //     ? await conversationClient.askGPT3(questionToUse, id)
    //     : await conversationClient.askGPT3FromCollection(
    //         questionToUse,
    //         authenticationToken,
    //         qI
    //       );
    // } catch (e) {
    //   if (e.type === "payment") {
    //     if (
    //       window.confirm(
    //         "You have run out of free credits, please create a subscription to continue"
    //       )
    //     ) {
    //       window.location.href = "/profile";
    //     }

    //     setMsgloading(false);
    //     return;
    //   }
    // }

    // let prevMessages = isSharing ? [] : withQuestion;

    // setMsg({
    //   ...msg,
    //   [id]: [
    //     ...prevMessages,
    //     {
    //       message: {
    //         answer: id ? response.data : response.data.answer || response.data,
    //         documents: id ? [] : response.data.documents,
    //       },
    //       paymentRequired: response.data.paymentRequired,
    //       isBot: true,
    //     },
    //   ],
    // });

    setMsgloading(false);

    // if (window.LO.events && window.LO.events.track) {
    //   window.LO.events.track("search");
    // }

    if (window.mixpanel && window.mixpanel.track) {
      // window.mixpanel.track("Search", {
      //   source: "Web App",
      //   question: q.substring(0, 200),
      // });
    }

    console.log(user);

    mixpanel.track("Search", {
      question: questionToUse,
      user_id: user.uid,
      name: user.displayName,
      email: user.email,
    });
  }

  const onTextChange = (event) => {
    setQuestion(event.target.value, false);
  };

  let onKeyPress = (e) => {
    if (e.keyCode === 13) {
      sendQuestion();
    }
  };

  let userClass = "border-gray-200 ";

  let botClass = "bg-gray-100 border-t border-gray-200 border-b pb-8";

  if (isSharing) {
    userClass = "";
    botClass = "";
  }

  // $font-size*$line-height*$lines-to-show
  const documentParagraphStyle = {
    "max-height": "200px",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    "text-overflow": "ellipsis",
  };

  return !msg[id] ? (
    <div className="flex w-full mt-10 justify-center">
      <Spinner />
    </div>
  ) : (
    <div className="h-screen flex flex-col overflow-hidden">
      <Banner />

      {isSharing && (
        <div className={"mt-10"}>
          <h1 className={"text-center"}>{sharingTitle} &nbsp;</h1>
          <div className={`p-6 justify-center flex w-full`}>
            <ConversationInput
              isClearAfterQuestion={false}
              isSendingQuestion={msgloading}
              onQuestionChange={(q) => setQuestion(q)}
              onSendQuestion={() => sendQuestion()}
            />
          </div>
        </div>
      )}

      {msg[id].length !== 0 && (
        <div
          className={`flex-grow ${
            isSharing
              ? "justify-center flex p-6 pt-0 pb-0 flex-wrap"
              : "items-center"
          } overflow-auto refined-scrollbar pb-2 pt-2`}
          ref={scrollRef}
        >
          {msg[id].map((message, i) => (
            <div
              className={
                `${isSharing ? "max-w-5xl w-full flex-grow" : "p-5"} ${
                  isSharing && !message.isBot ? "hidden" : ""
                }` + (!message.isBot ? userClass : botClass)
              }
            >
              <div className={"mb-0 flex w-full"} key={i}>
                <div>
                  <div className="h-8 w-8 bg-red-50 mr-3">
                    <img
                      className="h-8 w-8 bg-blue-200"
                      referrerpolicy="no-referrer"
                      style={{ backgroundColor: "transparent" }}
                      src={
                        !message.isBot ? user && user.photoURL : "/favicon.png"
                      }
                    />
                  </div>
                </div>

                <div
                  className={`${
                    !message.isBot && isSharing ? "hidden" : ""
                  } mt-1 whitespace-pre-line`}
                >
                  {message.isBot ? (
                    message.paymentRequired ? (
                      <div>
                        {!isSharing ? (
                          <div>
                            Your account has run out of credits, please visit
                            your{" "}
                            <a
                              className={"underline hover:no-underline"}
                              href="/profile"
                            >
                              profile
                            </a>{" "}
                            and pick a plan to continue.
                          </div>
                        ) : (
                          <div>
                            This account has run out of credits, please contact
                            your administrator.{" "}
                          </div>
                        )}
                      </div>
                    ) : id ? (
                      message.message
                    ) : (
                      message.message.answer
                    )
                  ) : (
                    message.message
                  )}
                </div>
              </div>

              {message.isBot && message.message.documents && (
                <div className={"text-sm flex"}>
                  <div className="h-8 w-8 mr-3 shrink-0	"></div>
                  <div>
                    {message.message.documents.map((document, i) => (
                      <div className={"max-w-2xl pt-5"}>
                        <div className={"mb-1"}>
                          <span
                            className={
                              "font-bold hover:underline inline-block mr-3"
                            }
                          >
                            {isHidingLink && isSharing ? (
                              <span className={"inline-block mr-1"}>
                                {document.file}
                              </span>
                            ) : (
                              <a href={document.file_url} target="_blank">
                                <span className={"inline-block mr-1"}>
                                  {document.file}
                                </span>{" "}
                                <FontAwesomeIcon
                                  icon={faExternalLink}
                                  size="xs"
                                />
                              </a>
                            )}
                          </span>
                          <span className={"inline-block italic text-gray-400"}>
                            Mentioning Confidence:{" "}
                            {Math.round(document.score * 100)}%
                          </span>
                        </div>
                        <div
                          className={"text-gray-500 mt-1"}
                          style={documentParagraphStyle}
                        >
                          {document.paragraph}
                        </div>
                        {/* <div className={"mt-1 text-gray-400 italic"}>
                          Page No. {document.page + 1}
                        </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {message.isBot && msg[id][msg[id].length - 1] === message && (
                <div className={"flex mt-4 hidden"}>
                  <div className="h-8 w-8 mr-3 shrink-0	"></div>
                  {getQuestionToRegenerate() && (
                    <div className={"mr-3 text-sm"}>
                      Not satisfied with the answer?{" "}
                      <span
                        onClick={() => regenerateResponse()}
                        className={
                          "cursor-pointer underline hover:no-underline"
                        }
                      >
                        Regenerate the response
                      </span>
                      .
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {!msg[id].length && !isSharing && (
        <div className={"flex-grow justify-center flex flex-col"}>
          <Logo />

          <Uploader uploaderLabel="Click or drop your PDF here" />

          <div className={"text-center mt-3"}>
            <div className={"text-lg"}>
              Upload your first PDF to get started!
            </div>
            <div className={"text-md"}>
              Try out a book, a research paper or a manual
            </div>
            {/* <div className={"text-md  italic mt-3"}>
              Max 1000 pages
            </div> */}
          </div>
        </div>
      )}

      <div
        className={`${
          isSharing ? "hidden" : ""
        } p-6 justify-center flex w-full self-end`}
      >
        <ConversationInput
          isClearAfterQuestion={true}
          isSendingQuestion={msgloading}
          onQuestionChange={(q) => setQuestion(q)}
          onSendQuestion={() => sendQuestion()}
        />
      </div>

      {isSharing && !isHidingLogo && (
        <div
          className={`${
            !isSharing ? "hidden" : ""
          } p-6 text-center text-gray-500`}
        >
          Powered by{" "}
          <Link to={`/`} target="_blank">
            <span className={"font-bold"}> Documind</span>
          </Link>
        </div>
      )}
    </div>
  );
}
