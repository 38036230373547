import React from "react";

export const PredefinedQuestions = ({
  onClick: externalOnClick,
  botSettingsForUse,
  sendQuestion,
}) => {
  const className =
    "inline-flex items-center bg-white text-xs sm:text-[13px] font-medium leading-6 shadow-sm border border-gray-400 transition-all duration-150 rounded-lg px-1 py-0 sm:px-2 sm:py-0 hover:text-gray-700 hover:border-gray-700 text-gray-500";

  return (
    <>
      {botSettingsForUse?.question1 && (
        <button
          type="button"
          className={className}
          onClick={() => {
            externalOnClick?.();
            sendQuestion(botSettingsForUse?.question1);
          }}
        >
          {botSettingsForUse?.question1}
        </button>
      )}
      {botSettingsForUse?.question2 && (
        <button
          type="button"
          className={className}
          onClick={() => {
            externalOnClick?.();
            sendQuestion(botSettingsForUse?.question2);
          }}
        >
          {botSettingsForUse?.question2}
        </button>
      )}
    </>
  );
};
