import React from "react";
import Spinner from "./Spinner";

export default function CenteredSpinner() {
  return (
    <div className="flex w-full mt-10 mb-10 justify-center overflow-hidden	">
      <Spinner />
    </div>
  );
}
