import dayjs from "dayjs";
import { collection, doc, getDocs } from "firebase/firestore";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import Context from "../../context";
import { db } from "../../firebase";

import Spinner from "../ui/Spinner";
import PageLayout from "./common/PageLayout";

import mixpanel from "mixpanel-browser";

export const MessageHistory = () => {
  const { botData } = useContext(Context);
  const [conversation, setConversation] = useState();
  const [loadingStatus, setLoadingStatus] = useState("initial");

  const [errorText, setErrorText] = useState(
    "There was a problem with fetching emails"
  );

  useEffect(() => {
    const getConversation = async () => {
      try {
        if (!botData?.bot_id) {
          setLoadingStatus("error");
          setErrorText(
            "You don't have bots just yet, create your first bot to see this page"
          );
        }

        setLoadingStatus("processing");
        const botRef = doc(db, "bots", botData.bot_id);
        const conversationRef = collection(botRef, "conversation");
        const conversationSnapshot = await getDocs(conversationRef);
        const conversationData = [];
        conversationSnapshot?.forEach((doc) => {
          if (doc.exists()) {
            const data = {
              ...doc.data(),
              date: dayjs(doc.data().timestamp).format("DD MMM YYYY HH:mm"),
            };
            conversationData.push(data);
          }
        });
        setConversation(conversationData);
        setLoadingStatus("success");
      } catch (error) {
        setLoadingStatus("error");
        console.error(error);
      }
    };
    getConversation();
  }, [botData]);

  useEffect(() => {
    mixpanel.track("Message History Page Visited", {
      botId: botData?.bot_id,
    });
  }, []);

  return (
    <div className="max-h-screen w-full overflow-auto pb-4 pt-[50px] lg:pt-0">
      <PageLayout heading="Message History">
        <div className="w-full h-full flex justify-center">
          {(loadingStatus === "processing" || loadingStatus === "initial") && (
            <Spinner />
          )}
          {loadingStatus === "error" && <p>{errorText}</p>}
          {loadingStatus === "success" && conversation && (
            <div className="w-full p-4 space-y-3">
              <DataTable
                value={conversation}
                sortMode="multiple"
                paginator
                rows={6}
                emptyMessage="No Messages just yet, make sure Widget is working on your Website"
                tableStyle={{ minWidth: "50rem" }}
                sortField="date"
                removableSort
              >
                <Column
                  field="question"
                  header="Question"
                  sortable
                  style={{ width: "15%" }}
                />
                <Column
                  field="answer"
                  header="Answer"
                  sortable
                  style={{ width: "70%" }}
                  className="whitespace-pre-wrap"
                />
                <Column
                  field="date"
                  header="Date"
                  sortable
                  style={{ width: "15%" }}
                />
              </DataTable>
            </div>
          )}
        </div>
      </PageLayout>
    </div>
  );
};
