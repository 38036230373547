import React, { useState } from "react";

import { DateTime } from "luxon";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import stripeClient from "../../../../clients/StripeClient";
import { auth } from "../../../../firebase";
import CenteredSpinner from "../../../ui/CenteredSpinner";
import PricingGrid from "../../../view/landingpage/partials/PricingGrid";

// botId couldn't be undefined in component
export default function UserSubscription({ botId }) {
  const [user, isUserLoading] = useAuthState(auth);
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (isUserLoading) return;
      // if (!botId) {
      //   return setIsLoading(false);
      // }
      if (!user) return navigate("/login");

      setSubscription(
        await stripeClient.getSubscription(await user.getIdToken())
      );
      setIsLoading(false);
    }

    fetchData();
  }, [user, isUserLoading]);

  async function openPortalSession() {
    const session = await stripeClient.startPortalSession(
      await user.getIdToken()
    );

    window.location.href = session.url;
  }

  function getHasSubLayout() {
    return (
      <div>
        <div>
          Next billing:{" "}
          {DateTime.fromSeconds(subscription.currentPeriodEnd).toISODate()}
        </div>
        <button
          className={
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-3"
          }
          onClick={() => openPortalSession()}
        >
          Manage
        </button>
      </div>
    );
  }

  return isLoading ? (
    <CenteredSpinner />
  ) : subscription ? (
    getHasSubLayout()
  ) : (
    <PricingGrid />
  );
}
