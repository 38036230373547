import React from "react";

const PageLayout = ({ heading, rightElement, children }) => {
  return (
    <div className="w-full">
      <div className="flex justify-between w-full items-center pr-6">
        {heading ? (
          <h1 className="justify-start py-4 px-6 text-2xl">{heading}</h1>
        ) : null}
        {rightElement}
      </div>
      {children}
    </div>
  );
};

export default PageLayout;
