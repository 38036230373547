import React, { useState } from "react";

import stripeClient from "../../../../clients/StripeClient";
import { auth } from "../../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

// NOTE: botId could be undefined, in this case, we want to redirect users to login
const PricingGrid = () => {
  const [user] = useAuthState(auth);
  const [annual, setAnnual] = useState(false);
  const navigate = useNavigate();

  async function checkout(priceId) {
    if (!user) {
      return navigate("/login");
    }

    const response = await stripeClient.checkout(
      await user.getIdToken(),
      priceId
    );

    window.location.href = response.url;
  }

  return (
    <div className="grid md:grid-cols-6">
      {/* Pricing toggle */}
      <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-50 md:col-span-3">
        <div className="flex justify-center md:justify-start items-center space-x-4">
          <div className="text-sm text-slate-700 font-medium min-w-[6rem] md:min-w-0 text-right">
            Monthly
          </div>
          <div className="form-switch shrink-0 ">
            <input
              type="checkbox"
              id="toggle"
              className="sr-only"
              checked={annual}
              onChange={() => setAnnual(!annual)}
            />
            <label className="bg-slate-300" htmlFor="toggle">
              <span className="bg-slate-200" aria-hidden="true" />
              <span className="sr-only">Pay annually</span>
            </label>
          </div>
          <div className="text-sm text-slate-700 font-medium min-w-[6rem]">
            Yearly <span className="text-emerald-500">(-50%)</span>
          </div>
        </div>
      </div>
      {/* Starter price */}
      <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-1 md:order-none md:text-center mt-6 md:mt-0">
        <div className="font-hkgrotesk text-lg font-bold text-indigo-500 mb-0.5">
          Starter
        </div>
        <div>
          <span className="text-xl font-semibold">$</span>
          <span className="text-2xl font-semibold">{annual ? "9" : "15"}</span>
          <span className="text-sm text-slate-500 font-medium">/mo</span>
        </div>
      </div>
      {/* Pro price */}
      <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-2 md:order-none md:text-center mt-6 md:mt-0">
        <div className="font-hkgrotesk text-lg font-bold text-indigo-500 mb-0.5">
          Pro
        </div>
        <div>
          <span className="text-xl font-semibold">$</span>
          <span className="text-2xl font-semibold">{annual ? "29" : "49"}</span>
          <span className="text-sm text-slate-500 font-medium">/mo</span>
        </div>
      </div>
      {/* Premium price */}
      <div className="flex flex-col justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-3 md:order-none md:text-center mt-6 md:mt-0">
        <div className="font-hkgrotesk text-lg font-bold text-indigo-500 mb-0.5">
          Premium
        </div>
        <div>
          <span className="text-xl font-semibold">$</span>
          <span className="text-2xl font-semibold">
            {annual ? "99" : "199"}
          </span>
          <span className="text-sm text-slate-500 font-medium">/mo</span>
        </div>
      </div>
      {/* Admins & Members */}
      <div className="md:flex hidden flex-col justify-center p-4 md:px-6 bg-slate-100 md:col-span-3">
        <div className="text-slate-800">Total messages</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-1 md:order-none">
        <div className="md:hidden text-slate-800">Total messages</div>
        <div className="text-sm font-medium text-slate-800 text-center">
          2000
        </div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-2 md:order-none">
        <div className="md:hidden text-slate-800">Total messages</div>
        <div className="text-sm font-medium text-slate-800 text-center">
          Unlimited
        </div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-3 md:order-none">
        <div className="md:hidden text-slate-800">Total messages</div>
        <div className="text-sm font-medium text-slate-800 text-center">
          Unlimited
        </div>
      </div>
      {/* File Storage */}
      <div className="md:flex hidden flex-col justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:col-span-3">
        <div className="text-slate-800">Bot customization</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-1 md:order-none">
        <div className="md:hidden text-slate-800">Bot customization</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-2 md:order-none">
        <div className="md:hidden text-slate-800">Bot customization</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-3 md:order-none">
        <div className="md:hidden text-slate-800">Bot customization</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div>
      {/* Multiple data sources */}
      {/* <div className="md:flex flex-col justify-center p-4 md:px-6 bg-slate-100 md:col-span-3">
        <div className="text-slate-800">Multiple data sources</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-1 md:order-none">
        <div className="md:hidden text-slate-800">Multiple data sources</div>
        <div className="text-sm font-medium text-slate-800 text-center">❌</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-2 md:order-none">
        <div className="md:hidden text-slate-800">Multiple data sources</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-3 md:order-none">
        <div className="md:hidden text-slate-800">Multiple data sources</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div> */}
      {/* Multiple bots */}
      {/* <div className="md:flex flex-col justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:col-span-3">
        <div className="text-slate-800">Multiple bots</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-1 md:order-none">
        <div className="md:hidden text-slate-800">Multiple bots</div>
        <div className="text-sm font-medium text-slate-800 text-center">❌</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-2 md:order-none">
        <div className="md:hidden text-slate-800">Multiple bots</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-3 md:order-none">
        <div className="md:hidden text-slate-800">Multiple bots</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div> */}
      {/* Email collection */}
      <div className="md:flex hidden flex-col justify-center p-4 md:px-6 bg-slate-100 md:col-span-3">
        <div className="text-slate-800">Collect email</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-1 md:order-none">
        <div className="md:hidden text-slate-800">Collect email</div>
        <div className="text-sm font-medium text-slate-800 text-center">❌</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-2 md:order-none">
        <div className="md:hidden text-slate-800">Collect email</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 md:border-l border-slate-200 order-3 md:order-none">
        <div className="md:hidden text-slate-800">Collect email</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div>
      {/* remove branding */}
      <div className="md:flex hidden flex-col justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:col-span-3">
        <div className="text-slate-800">Remove VivoChat branding</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-1 md:order-none">
        <div className="md:hidden text-slate-800">Remove VivoChat branding</div>
        <div className="text-sm font-medium text-slate-800 text-center">❌</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-2 md:order-none">
        <div className="md:hidden text-slate-800">Remove VivoChat branding</div>
        <div className="text-sm font-medium text-slate-800 text-center">❌</div>
      </div>
      <div className="flex justify-between md:flex-col md:justify-center p-4 md:px-6 bg-slate-100 bg-opacity-70 md:border-l border-slate-200 order-3 md:order-none">
        <div className="md:hidden text-slate-800">Remove VivoChat branding</div>
        <div className="text-sm font-medium text-slate-800 text-center">✔️</div>
      </div>
      <div className="md:flex flex-col justify-center px-4 md:px-6 md:py-2 bg-slate-300 bg-opacity-25 md:col-span-3" />
      <div className="flex flex-col justify-center p-4 bg-slate-300 bg-opacity-25 md:border-l border-slate-200 order-1 md:order-none">
        <button
          className="btn-sm text-white bg-indigo-500 hover:bg-indigo-600 w-full shadow-sm group whitespace-nowrap"
          onClick={() =>
            checkout(
              annual
                ? process.env.REACT_APP_YEARLY_STARTER_PRICE_ID
                : process.env.REACT_APP_STARTER_PRICE_ID
            )
          }
        >
          {user ? "Subscribe" : "Join now"}
        </button>
      </div>
      <div className="flex flex-col justify-center p-4 bg-slate-300 bg-opacity-25 md:border-l border-slate-200 order-2 md:order-none">
        <button
          className="btn-sm text-white bg-indigo-500 hover:bg-indigo-600 w-full shadow-sm group whitespace-nowrap"
          onClick={() =>
            checkout(
              annual
                ? process.env.REACT_APP_YEARLY_PRO_PRICE_ID
                : process.env.REACT_APP_PRO_PRICE_ID
            )
          }
        >
          {user ? "Subscribe" : "Join now"}
        </button>
      </div>
      <div className="flex flex-col justify-center p-4 bg-slate-300 bg-opacity-25 md:border-l border-slate-200 order-3 md:order-none">
        <button
          className="btn-sm text-white bg-indigo-500 hover:bg-indigo-600 w-full shadow-sm group whitespace-nowrap"
          onClick={() =>
            checkout(
              annual
                ? process.env.REACT_APP_YEARLY_PREMIUM_PRICE_ID
                : process.env.REACT_APP_PREMIUM_PRICE_ID
            )
          }
        >
          {user ? "Subscribe" : "Join now"}
        </button>
      </div>
    </div>
  );
};

export default PricingGrid;
