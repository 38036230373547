class UserClient {
  async login(idToken) {
    const data = new FormData();
    data.append("idToken", idToken);

    const responseJson = await fetch(process.env.REACT_APP_API_URL + "/login", {
      method: "POST",
      body: data,
    });

    return await responseJson.json();
  }

  async getUser(authorizationToken) {
    const responseJson = await fetch(process.env.REACT_APP_API_URL + "/user", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer: " + authorizationToken,
      },
    });

    return await responseJson.json();
  }
}

const userClient = new UserClient();
export default userClient;
