import React, { useContext, useEffect } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import Context from "../../../context";
import { auth } from "../../../firebase";
import PageLayout from "../common/PageLayout";
import UserSubscription from "./parts/UserSubscription";

export default function Profile() {
  const [firebaseUser] = useAuthState(auth);
  const { botData } = useContext(Context);

  return (
    <div className="max-h-screen w-full overflow-auto pb-4 pt-[50px] lg:pt-0">
      <PageLayout heading="Profile">
        <div className="flex-grow mx-6 space-y-3">
          <div>
            <h2 className="font-bold text-lg">Basic Info</h2>
            <div>
              {firebaseUser.displayName && (
                <div className="em">Name: {firebaseUser.displayName}</div>
              )}
              {firebaseUser.email && (
                <div className="em">Email: {firebaseUser.email}</div>
              )}
            </div>
          </div>

          {botData && (
            <div>
              <h2 className="font-bold text-lg">Credits</h2>
              <div>
                Remaining credits:{" "}
                {Number(botData.chats_limit) - Number(botData.chats_count)}
              </div>
            </div>
          )}
          <div>
            <h2 className="font-bold text-lg">Subscription</h2>
            <UserSubscription />
          </div>
        </div>
      </PageLayout>
    </div>
  );
}
