import React from "react";
import { Link } from "react-router-dom";

import Illustration from "../images/hero-illustration.svg";
import HeroImage from "../images/hero-image.png";
import Herogif from "../images/corporademovideo.mp4";
import { WidgetSettings } from "../../../pages/WidgetSettings/WidgetSettings";

function Hero() {
  return (
    <section className="relative">
      {/* Illustration */}
      <div
        className="md:block absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
        aria-hidden="true"
      >
        <img
          src={Illustration}
          className="max-w-none"
          width="1440"
          height="1265"
          alt="Hero Illustration"
        />
      </div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-40">
          {/* Hero content */}
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="h1 font-hkgrotesk mb-6" data-aos="fade-up">
              AI ChatBot for your Website
            </h1>
            <p
              className="text-xl text-slate-500 mb-10"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              ⚡Put AI ChatBot on your website in 5 seconds. No signup required
              {/* Build a custom ChatGPT like chatbot for your own documents. Add your new chatbot on your own website or share it with your team. */}
              {/* <br></br><br></br>New: ✨ Now you can share your chatbot with others */}
            </p>
          </div>
          {/* Hero image */}

          {/* <div><ReactPlayer url={'../images/corporademovideo.mp4'} controls={false} /></div> */}
          {/* <center>
            <div>
              <video
                style={{ borderRadius: "15px" }}
                width="920"
                height="518"
                autoPlay
                loop
                muted
              >
                <source src={Herogif} type="video/mp4" />
              </video>
            </div>
          </center> */}
        </div>
      </div>
      <WidgetSettings isDemoVersion isForInternalUse />
    </section>
  );
}

export default Hero;
