import React, { useState } from "react";

import { TabPanel, TabView } from "primereact/tabview";

import TrainBySitemap from "./parts/TrainBySitemap";
import TrainByPDF from "./parts/TrainByPDF";

import PageLayout from "../common/PageLayout";

export const TrainingData = () => {
  const [tabActiveIndex, setTabActiveIndex] = useState(0);

  return (
    <PageLayout heading="Training Data">
      <div className="w-full h-full flex flex-col items-center">
        <TabView
          className="w-full"
          activeIndex={tabActiveIndex}
          onTabChange={(e) => setTabActiveIndex(e.index)}
        >
          <TabPanel header="Sitemaps" className="flex flex-col items-center">
            <TrainBySitemap tabActiveIndex={tabActiveIndex} />
          </TabPanel>
          <TabPanel header="PDF">
            <TrainByPDF tabActiveIndex={tabActiveIndex} />
          </TabPanel>
        </TabView>
      </div>
    </PageLayout>
  );
};
