import Sidebar from "../view/Sidebar";

import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import Context from "../../context";
import { auth, db } from "../../firebase";
import CenteredSpinner from "../ui/CenteredSpinner";
import { Leads } from "./Leads";
import Profile from "./Profile/Profile";
import { WidgetSettings } from "./WidgetSettings/WidgetSettings";
import { MessageHistory } from "./MessageHistory";
import { TrainingData } from "./TrainingData/TrainingData";
import { doc, getDoc } from "firebase/firestore";
import { Button } from "flowbite-react";
import { toast } from "react-toastify";
import { sendEmailVerification } from "firebase/auth";

import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [user, isLoading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [authenticationToken, setAuthenticationToken] = useState(null);
  const [botData, setBotData] = useState();
  const [firestoreUser, setFirestoreUser] = useState();

  const [loadingStatus, setLoadingStatus] = useState("initial");

  const value = {
    user,
    firestoreUser,
    botData,
    authenticationToken,
    setFirestoreUser,
    setBotData,
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setLoadingStatus("processing");
        if (isLoading) return;
        if (!user) return navigate("/login");
        setAuthenticationToken(await user.getIdToken());
        const userDocData = await getDoc(
          doc(db, "users", auth.currentUser.uid)
        );
        if (userDocData.exists()) {
          setFirestoreUser(userDocData.data());
          const botDocData = await getDoc(
            doc(db, "bots", userDocData.data().bot_id)
          );
          if (botDocData.exists()) {
            setBotData(botDocData.data());
            setLoadingStatus("success");
          }
        }
      } catch (error) {
        setLoadingStatus("error");
      }
    }

    fetchData();
  }, [user, isLoading]);

  const sendVerificationEmail = async (user) => {
    try {
      await sendEmailVerification(user);
      toast.success(`Verification email sent to: ${user.email}`);
    } catch (error) {
      console.error("Error sending verification email:", error.message);
      toast.error(`Error sending verification email: ${user.email}`);
    }
  };

  function loadingView() {
    return <CenteredSpinner />;
  }

  function dashboardView() {
    return (
      <>
        <div className="w-full overflow-hidden">
          <div className="flex w-full">
            <Sidebar isNavDisabled={!user.emailVerified} />

            {user.emailVerified ? (
              <Routes>
                <Route path="/*" element={<Navigate to="widget" replace />} />
                <Route
                  path="/widget"
                  element={
                    <div className="max-h-screen w-full overflow-auto pb-4 pt-[50px] lg:pt-0">
                      <WidgetSettings withHeading />
                    </div>
                  }
                />
                <Route path="/leads" element={<Leads />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/message-history" element={<MessageHistory />} />

                <Route path="/train" element={<TrainingData />} />
              </Routes>
            ) : (
              <div class="relative flex w-full flex-col items-center overflow-hidden py-16 bg-white">
                <div class="max-w-xl px-5 text-center flex flex-col items-center">
                  <h2 class="mb-2 text-5xl font-bold text-zinc-800">
                    Check your inbox
                  </h2>
                  <p class="mb-2 text-lg text-zinc-700">
                    We are glad, that you’re with us! We’ve sent you a
                    verification link to the email address:{" "}
                    <span class="font-medium text-indigo-500">
                      {user.email}
                    </span>
                    .
                  </p>
                  <Button
                    className="w-[70%] mt-4"
                    color="dark"
                    onClick={() => sendVerificationEmail(user)}
                  >
                    Resend Verification Link
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <Context.Provider value={value}>
      <div className="flex">
        {loadingStatus === "initial" || loadingStatus === "processing"
          ? loadingView()
          : dashboardView()}
      </div>
    </Context.Provider>
  );
}
