import React, { useState } from "react";
import { useFormik } from "formik";
import { TextInput, Label, Button } from "flowbite-react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import { signInWithEmail, signUpWithEmail } from "../../../../../firebase";

const EmailPasswordForm = ({ mode = "sign-in" }) => {
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().when("mode", {
      is: "sign-up",
      then: () =>
        Yup.string()
          .required("Required")
          .min(8, "Password must be at least 8 characters long"),
      otherwise: () => Yup.string().required("Required"),
    }),
    confirmPassword: Yup.mixed().when("mode", {
      is: "sign-up",
      then: () =>
        Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Required"),
    }),
  });
  const [loadingStatus, setLoadingStatus] = useState("initial");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      mode,
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoadingStatus("processing");
      try {
        if (values.mode === "sign-in") {
          await signInWithEmail(values.email, values.password);
        } else {
          await signUpWithEmail(values.email, values.password);
        }
        navigate("/dashboard");
        setLoadingStatus("success");
      } catch (error) {
        formik.setFieldError("general", error.message);
        setLoadingStatus("error");
      }
    },
  });

  return (
    <div className="flex items-center justify-center">
      <div className="max-w-md w-full space-y-8">
        <form onSubmit={formik.handleSubmit}>
          <input type="hidden" name="remember" value="true" />
          <div className="space-y-6">
            <div className="rounded-md shadow-sm space-y-2">
              <div className="space-y-1">
                <Label htmlFor="email" value="Email" />
                <TextInput
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  {...formik.getFieldProps("email")}
                  placeholder="Email address"
                  color={
                    formik.touched.email && formik.errors.email
                      ? "failure"
                      : "black"
                  }
                  helperText={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : undefined
                  }
                />
              </div>
              <div className="space-y-1">
                <Label htmlFor="password" value="Password" />
                <TextInput
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  {...formik.getFieldProps("password")}
                  placeholder="Password"
                  color={
                    formik.touched.password && formik.errors.password
                      ? "failure"
                      : "black"
                  }
                  helperText={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : undefined
                  }
                />
              </div>
              {formik.values.mode === "sign-up" && (
                <div className="space-y-1">
                  <Label htmlFor="confirmPassword" value="Confirm Password" />
                  <TextInput
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="current-password"
                    required={formik.values.mode === "sign-up"}
                    {...formik.getFieldProps("confirmPassword")}
                    placeholder="Confirm Password"
                    color={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                        ? "failure"
                        : "black"
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                        ? formik.errors.confirmPassword
                        : undefined
                    }
                  />
                </div>
              )}
            </div>
            <div>
              <Button
                type="submit"
                disabled={formik.isSubmitting}
                gradientDuoTone="purpleToBlue"
                isProcessing={loadingStatus === "processing"}
                className="w-full"
              >
                <p className="font-medium">
                  {formik.values.mode === "sign-in" ? "Sign In" : "Sign Up"}
                </p>
              </Button>
            </div>
            {formik.errors.general && (
              <div className="text-sm text-red-600">
                {formik.errors.general}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailPasswordForm;
