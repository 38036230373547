import {
  faBars,
  faClose,
  faRightFromBracket,
  faUser,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import "react-dropdown/style.css";
import "reactjs-popup/dist/index.css";
import { logout } from "../../firebase";

export const NavItem = ({ href, isDisabled, children }) => {
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        ` font-medium flex py-2 px-4 rounded-full ${
          isActive ? "bg-indigo-100" : ""
        } ${isDisabled ? "opacity-50 cursor-not-allowed" : "hover:underline"}`
      }
      onClick={(e) => {
        if (isDisabled) {
          e.preventDefault();
        }
      }}
    >
      {children}
    </NavLink>
  );
};

const NavToggleButton = ({ setIsNavOpen, isNavOpen }) => {
  return (
    <button
      dataDrawerTarget="default-sidebar"
      dataDrawerToggle="default-sidebar"
      aria-controls="default-sidebar"
      type="button"
      className="inline-flex h-fit items-center py-1 px-2 text-sm text-gray-700 rounded-sm lg:hidden focus:outline-none focus:ring-2 focus:ring-gray-200"
      onClick={() => setIsNavOpen((a) => !a)}
    >
      <span className="sr-only">Open sidebar</span>
      {isNavOpen ? (
        <FontAwesomeIcon icon={faClose} size="lg" />
      ) : (
        <FontAwesomeIcon icon={faBars} />
      )}
    </button>
  );
};

export default function Sidebar({ isNavDisabled }) {
  const [isNavOpen, setIsNavOpen] = useState(false);
  return (
    <>
      <aside
        className={`min-w-[250px] fixed lg:relative bg-gray-50 top-0 left-0 z-40 w-64 h-screen transition-transform lg:translate-x-0 border-r shadow-r ${
          isNavOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        ariaLabel="Sidebar"
      >
        <div className="h-full py-2 flex flex-col space-y-3 divide-y divide-solid">
          <div className="flex justify-between px-3 items-center">
            <Link
              to="/"
              className="text-xl font-bold flex hover:text-indigo-600"
            >
              Vivo Chat
            </Link>
            <NavToggleButton
              setIsNavOpen={setIsNavOpen}
              isNavOpen={isNavOpen}
            />
          </div>
          <div className="flex flex-col gap-2 p-3 grow">
            <NavItem isDisabled={isNavDisabled} href="/dashboard/widget">
              Bot Settings
            </NavItem>
            <NavItem isDisabled={isNavDisabled} href="/dashboard/train">
              Bot Training
            </NavItem>
            <NavItem
              isDisabled={isNavDisabled}
              href="/dashboard/message-history"
            >
              Message History
            </NavItem>
            <NavItem isDisabled={isNavDisabled} href="/dashboard/leads">
              Leads
            </NavItem>
            <NavItem isDisabled={isNavDisabled} href="/dashboard/profile">
              Profile
            </NavItem>
            {/* <NavItem href="/dashboard/embed">Embed</NavItem> */}
          </div>

          <div className="px-4 justify-self-end">
            {/* <div>
              <Link to={`/dashboard/profile`}>
                <div className="dashboard__btn p-2 hover:underline cursor-pointer space-x-2">
                  <FontAwesomeIcon icon={faUser} />
                  <span>Profile</span>
                </div>
              </Link>
            </div> */}
            <div>
              <div
                className="dashboard__btn py-3 hover:underline cursor-pointer space-x-2"
                onClick={logout}
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
                <span>Logout</span>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <nav className="h-[50px] w-full bg-gray-50 fixed lg:hidden flex px-3 items-center border-b z-30">
        <NavToggleButton setIsNavOpen={setIsNavOpen} isNavOpen={isNavOpen} />
      </nav>
    </>
  );
}
