import React, { useEffect, useRef, useState } from "react";

import { BouncingLoader } from "../../ui/BouncingLoader";

import { getBot } from "../../../clients/BotClient";
import { BotMessage } from "./parts/BotMessage";
import { CloseIcon } from "./parts/CloseIcon";
import { EmailFormMessage } from "./parts/EmailFormMessage";
import { PredefinedQuestions } from "./parts/PredefinedQuestions";
import { SendIcon } from "./parts/SendIcon";
import { UserMessage } from "./parts/UserMessage";

import { SSE } from "sse";

const Widget = ({
  isOpen: isWidgetOpen = false,
  isForInternalUse = false,
  externalBotId,
  settings = {
    websiteURL: "",
    brandName: "",
    brandColor: "",
    question1: "",
    question2: "",
    askForEmail: true,
    removeBranding: false,
  },
}) => {
  const scriptTag = document.currentScript;
  const [isOpen, setIsOpen] = useState(isWidgetOpen);
  const [botSettings, setBotSettings] = useState();
  const [isEmailFormShown, setIsEmailFormShown] = useState(true);
  const toggleVisibility = () => setIsOpen((a) => !a);

  const botSettingsForUse = isForInternalUse ? settings : botSettings;

  const [botId, setBotId] = useState();

  const [isMessageSending, setIsMessageSending] = useState(false);
  const [isWaitingOnStreamingMessage, setIsWaitingOnStreamingMessage] =
    useState(false);
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState("");
  let [result, setResult] = useState("");

  const resultRef = useRef();
  const scrollRef = useRef();

  useEffect(() => {
    resultRef.current = result;
  }, [result]);

  useEffect(() => {
    if (scriptTag) {
      setBotId(scriptTag.getAttribute("vivochat-bot-id"));
    }
  }, [scriptTag, isForInternalUse]);

  useEffect(() => {
    const getBotData = async () => {
      const bot = await getBot({ bot_id: botId });

      if (bot?.url) {
        setBotSettings({
          websiteURL: bot?.url,
          brandName: bot?.name,
          brandColor: bot?.color,
          question1: bot?.question1 || "",
          question2: bot?.question2 || "",
          askForEmail: bot?.ask_for_email ?? false,
          removeBranding: bot?.remove_branding ?? false,
        });
      }
    };

    if (!isForInternalUse && botId) {
      getBotData();
    }
  }, [botId]);

  // Scroll down if message is generated
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
    }
  }, [messages]);

  const sendQuestion = (externalQuestion) => {
    if (isMessageSending) {
      return;
    }

    const internalQuestion = externalQuestion || question;

    if (!internalQuestion) {
      return;
    }

    setQuestion("");
    setIsMessageSending(true);
    setIsWaitingOnStreamingMessage(true);

    const messagesWithQuestion = [
      ...messages,
      {
        message: internalQuestion,
        isBot: false,
      },
    ];

    setMessages(messagesWithQuestion);

    try {
      const payloadData = {
        question: internalQuestion,
        bot_id: botId ?? externalBotId,
      };

      if (internalQuestion) {
        setResult("");
        // let url = "http://localhost:8080/ask-from-website";
        let url = process.env.REACT_APP_API_URL + "/ask-from-website";

        let source = new SSE(url, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          payload: JSON.stringify(payloadData),
        });

        source.addEventListener("message", (e) => {
          if (e.data != "[DONE]") {
            let payload = JSON.parse(e.data);
            let text = payload.data.answer;
            if (text != "\n") {
              resultRef.current = resultRef.current + text;
              setResult(resultRef.current);
              setIsWaitingOnStreamingMessage(false);
              setMessages([
                ...messagesWithQuestion,
                {
                  message: payload.data.answer,
                  isBot: true,
                },
              ]);
            }
          } else {
            source.close();
          }
        });

        source.addEventListener("readystatechange", (e) => {
          if (e.readyState >= 2) {
            setIsMessageSending(false);
          }
        });

        source.stream();
      } else {
        alert("Please insert a prompt!");
        setIsMessageSending(false);
        setIsWaitingOnStreamingMessage(false);
      }
    } catch (e) {
      if (e.type === "payment") {
        setIsMessageSending(false);
        return;
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (botId ?? externalBotId) {
      sendQuestion();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <div
      className={`flex-col ${
        isForInternalUse ? "z-1" : "z-[99999]"
      } w-fit font-sans bottom-[2vh] right-[1vw] sm:right-3 sm:left-auto ${
        isForInternalUse ? "relative" : "fixed"
      }`}
    >
      <div
        className={`${
          isOpen ? "flex" : "hidden"
        } flex-col bg-white border w-[90vw] sm:w-[450px] max-h-[750px] h-[80vh] rounded-xl shadow-md bottom-[65px] right-0 absolute`}
      >
        <header
          className="shrink-0 px-4 py-3 border-b rounded-tl-xl rounded-tr-xl"
          style={{ background: botSettingsForUse?.brandColor }}
        >
          <div className="flex items-center justify-between">
            <p className="flex-1 text-lg font-bold text-white mr-auto">
              {botSettingsForUse?.brandName}
            </p>
            <div className="flex items-center justify-end gap-6">
              <button
                type="button"
                className="text-white transition-all duration-150 -m-2 rounded-full p-2"
                onClick={toggleVisibility}
              >
                <span className="sr-only">Close</span>
                <CloseIcon />
              </button>
            </div>
          </div>
        </header>
        <div
          ref={scrollRef}
          className="flex min-h-0 flex-1 flex-col overflow-y-auto overflow-x-hidden py-5"
        >
          <div className="flex flex-1 flex-col justify-end gap-6">
            <div>
              <div className="flex flex-col space-y-4 px-4 items-start">
                <BotMessage>Hello! How can I assist you today?</BotMessage>

                {messages.map((message, i) => {
                  if (message.isBot) {
                    return <BotMessage key={i}>{message.message}</BotMessage>;
                  }

                  return (
                    <UserMessage key={i} botSettingsForUse={botSettingsForUse}>
                      {message.message}
                    </UserMessage>
                  );
                })}

                {isWaitingOnStreamingMessage ? (
                  <BotMessage>
                    <BouncingLoader />
                  </BotMessage>
                ) : null}

                {botSettingsForUse?.askForEmail &&
                !isMessageSending &&
                messages.length ? (
                  <EmailFormMessage
                    botSettingsForUse={botSettingsForUse}
                    botId={botId ?? externalBotId}
                    isEmailFormShown={isEmailFormShown}
                    setIsEmailFormShown={setIsEmailFormShown}
                  />
                ) : null}
              </div>
            </div>
            <div className="flex flex-col items-end space-y-1.5 px-4">
              <div className="flex flex-wrap justify-end gap-2">
                <PredefinedQuestions
                  botSettingsForUse={botSettingsForUse}
                  sendQuestion={sendQuestion}
                />
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <label htmlFor="chat" className="sr-only">
            Your message
          </label>
          <div className="flex items-center border-t pr-3">
            <textarea
              id="chat"
              rows="1"
              className="block mr-2 w-full text-sm p-5 text-gray-900 bg-white rounded-lg border-none border-gray-300 focus:ring-transparent focus:border-transparent max-h-24"
              placeholder="Your message..."
              value={question}
              disabled={isMessageSending}
              onChange={(e) => setQuestion(e.target.value)}
              onKeyDown={handleKeyPress}
            />
            <button
              type="submit"
              className="inline-flex justify-center p-2 rounded-full cursor-pointer hover:bg-gray-100"
              style={{ color: botSettingsForUse?.brandColor }}
              disabled={isMessageSending || !question}
            >
              <SendIcon fill={botSettingsForUse?.brandColor} />
              <span className="sr-only">Send message</span>
            </button>
          </div>
        </form>
        {!botSettingsForUse?.removeBranding && (
          <footer className="shrink-0 border-t py-2 border-gray-200 px-4 rounded-bl-xl rounded-br-xl">
            <div className="flex items-center justify-center gap-1.5">
              <p className="text-sm font-medium text-gray-500 m-0">
                Powered by
              </p>
              <div className="flex items-center gap-1">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.vivochat.ai/"
                  className="text-sm font-semibold tracking-tight text-gray-900 transition-all duration-150 hover:text-indigo-600 hover:underline"
                >
                  VivoChat
                </a>
              </div>
            </div>
          </footer>
        )}
      </div>

      {botSettingsForUse ? (
        <div className="flex flex-col gap-3 items-end font-sans">
          <div className="flex flex-col gap-2 items-end">
            <PredefinedQuestions
              onClick={toggleVisibility}
              botSettingsForUse={botSettingsForUse}
              sendQuestion={sendQuestion}
            />
          </div>

          <button
            className="rounded-full w-14 h-14 flex items-center justify-center text-white"
            style={{ background: botSettingsForUse?.brandColor }}
            onClick={toggleVisibility}
          >
            {isOpen ? (
              <CloseIcon />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.4em"
                fill="white"
                viewBox="0 0 512 512"
              >
                <path d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6 .6-1 1.1-1.3 1.4l-.3 .3 0 0 0 0 0 0 0 0c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM128 208a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm128 0a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm96 32a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
              </svg>
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Widget;
