import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";

import { Button, Label, TextInput, ToggleSwitch } from "flowbite-react";
import Widget from "../../view/Widget/Widget";
import PageLayout from "../common/PageLayout";
import Spinner from "../../ui/Spinner";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CopyableText from "../../ui/CopyableText";

import PreviewButtonLink from "./parts/PreviewButtonLink";

import { auth, db } from "../../../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

import { getBot, saveBot } from "../../../clients/BotClient";
import { v4 as uuid } from "uuid";
import mixpanel from "mixpanel-browser";

import "../../../tailwind.css";

// {
//   websiteURL: "https://www.vivochat.ai/",
//   brandName: "Vivo Chat",
//   brandColor: "#6366f1",
//   question1: "How to use Vivo Chat?",
//   question2: "Give me pricing plans",
//   askForEmail: false,
//   removeBranding: false,
// };

const demoWidgetSettings = {
  websiteURL: "https://www.tesla.com/",
  brandName: "Tesla Bot",
  brandColor: "#6366f1",
  question1: "Give me Tesla models list",
  question2: "What is price of model X?",
  askForEmail: false,
  removeBranding: false,
};
const demoBotId = "a95c705e-55ac-4fd2-9733-3574daa00292";

export const WidgetSettings = ({ withHeading, isDemoVersion = false }) => {
  let navigate = useNavigate();
  const [loadingStatus, setLoadingStatus] = useState("initial");
  const [botId, setBotId] = useState();
  const [userInfoData, setUserInfoData] = useState();

  const sharingScript = `<script async src="https://www.vivochat.ai/dist/widget.js" vivochat-bot-id="${botId}"></script>`;

  const {
    values,
    isSubmitting,
    submitCount,
    setValues,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: isDemoVersion
      ? demoWidgetSettings
      : {
          websiteURL: "",
          brandName: "Your Company",
          brandColor: "black",
          question1: "",
          question2: "",
          askForEmail: false,
          removeBranding: false,
        },
    enableReinitialize: true,
    onSubmit: async (settings) => {
      // Use existing bot id or create new one
      try {
        const botIdToSend = botId ? botId : uuid();
        const botSettings = {
          bot_id: botIdToSend,
          // If there is no authenticated user, send empty user_id
          user_id: auth?.currentUser?.uid || "",
          url: settings.websiteURL,
          name: settings.brandName,
          color: settings.brandColor,
          question_1: settings.question1 || "",
          question_2: settings.question2 || "",
          ask_for_email: settings.askForEmail,
          remove_branding: settings.removeBranding,
        };

        const data = await saveBot(botSettings);

        // Assigning bot id for authenticated user
        if (data.status === 200 && auth?.currentUser?.uid && !isDemoVersion) {
          await setDoc(
            doc(db, "users", auth.currentUser.uid),
            {
              bot_id: botIdToSend,
            },
            { merge: true }
          );
        }
        if (data.status === 200) {
          if (!settingsSaved) {
            setIsBotCreatedModalVisible(true);
            setSettingsSaved(true);
            mixpanel.track("Chatbot Created", {
              user_id: auth?.currentUser?.uid,
              bot_id: botIdToSend,
              botSettings,
            });
          }
          setBotId(botIdToSend);
        }
        toast.success("Your Bot Settings Saved!");
        if (data.status === 500) {
          toast.error("Your Bot Settings can't be saved.");
        }
      } catch (error) {
        toast.error("Your Bot Settings can't be saved.");
      }
    },
  });

  const [settingsSaved, setSettingsSaved] = useState(submitCount > 0);
  const [isBotCreatedModalVisible, setIsBotCreatedModalVisible] =
    useState(false);

  function copyEmbedScript() {
    navigator.clipboard.writeText(sharingScript);
    toast.success("Embed Script Copied!");
    mixpanel.track("Embed Script Copied", {
      user_id: auth?.currentUser?.uid,
      botId,
      botSettings: values,
    });
  }

  const onPreviewClick = () => {
    mixpanel.track("Website Preview Activated", {
      user_id: auth?.currentUser?.uid,
      botId,
      botSettings: values,
    });
  };

  useEffect(() => {
    const fetchBot = async () => {
      try {
        setLoadingStatus("processing");
        // If user is not authenticated - no need to get settings
        if (auth?.currentUser?.uid) {
          const userData = await getDoc(doc(db, "users", auth.currentUser.uid));
          if (userData.exists()) {
            setUserInfoData(userData.data());
            const botId = userData.data().bot_id;
            if (botId) {
              setBotId(botId);
              const bot = await getBot({ bot_id: botId });
              if (bot?.url) {
                setSettingsSaved(true);
                setValues({
                  websiteURL: bot?.url,
                  brandName: bot?.name,
                  brandColor: bot?.color,
                  question1: bot?.question1,
                  question2: bot?.question2,
                  askForEmail: bot?.ask_for_email ?? false,
                  removeBranding: bot?.remove_branding ?? false,
                });
              }
            }
          }
        }
        setLoadingStatus("success");
      } catch (error) {
        console.error("Error fetching bot:", error);
        setLoadingStatus("error");
      }
    };

    if (!isDemoVersion) {
      fetchBot();
    } else {
      setLoadingStatus("success");
    }
    console.log(isDemoVersion);
  }, [isDemoVersion]);

  return (
    <PageLayout heading={withHeading ? "Bot Settings" : ""}>
      <div className="w-full h-full flex justify-center">
        {(loadingStatus === "processing" || loadingStatus === "initial") && (
          <Spinner />
        )}
        {loadingStatus === "error" && (
          <p>There was a problem with fetching settings for your Widget</p>
        )}

        {loadingStatus === "success" && (
          <div className="min-h-[90vh] w-full xl:w-[70vw] relative flex flex-col-reverse md:mt-0 mt-[80vh] md:flex-row md:items-center justify-center md:pr-[500px]">
            <div className="flex flex-col w-full gap-10 min-w-[250px] p-5">
              {isDemoVersion ? (
                <div className="space-y-4">
                  <p className="font-extrabold text-2xl md:text-3xl text-indigo-600 text-right hidden md:block">
                    Try it out 👉
                  </p>
                  {/* Mobile Version */}
                  <p className="font-extrabold text-2xl md:text-3xl text-indigo-600 text-right md:hidden">
                    Try it out ☝️
                  </p>
                  {/*  */}
                  <p className="font-extrabold text-2xl md:text-3xl text-indigo-600">
                    Create your own Chatbot! 👇
                  </p>
                </div>
              ) : null}
              <form
                className="w-full space-y-10 flex flex-col items-center"
                onSubmit={handleSubmit}
              >
                <div className="space-y-3 w-full">
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="websiteURL" value="Website URL" />
                    </div>
                    <TextInput
                      id="websiteURL"
                      placeholder="www"
                      required
                      type="text"
                      color="black"
                      value={values.websiteURL}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="brandName" value="Brand Name" />
                    </div>
                    <TextInput
                      id="brandName"
                      placeholder="My Company"
                      required
                      type="text"
                      color="black"
                      value={values.brandName}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="brandColor" value="Brand Color" />
                    </div>
                    <TextInput
                      color="black"
                      id="brandColor"
                      placeholder="#00000"
                      required
                      type="text"
                      value={values.brandColor}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="question1"
                        value="Predefined Question 1"
                      />
                    </div>
                    <TextInput
                      color="black"
                      id="question1"
                      placeholder="Question"
                      type="text"
                      required
                      value={values.question1}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label
                        htmlFor="question2"
                        value="Predefined Question 2"
                      />
                    </div>
                    <TextInput
                      color="black"
                      id="question2"
                      placeholder="Question"
                      type="text"
                      required
                      value={values.question2}
                      onChange={handleChange}
                    />
                  </div>
                  {!isDemoVersion ? (
                    <>
                      <ToggleSwitch
                        label="Ask for email"
                        id="askForEmail"
                        color="gray"
                        checked={values.askForEmail}
                        onChange={() => {
                          handleChange({
                            target: {
                              name: "askForEmail",
                              value: !values.askForEmail,
                            },
                          });
                        }}
                      />
                      <ToggleSwitch
                        label={
                          userInfoData?.subscription_status === "$199 Premium"
                            ? "Remove VivoChat Branding"
                            : "Remove VivoChat Branding (Premium Plan)"
                        }
                        id="askForEmail"
                        color="gray"
                        disabled={!userInfoData}
                        checked={values.removeBranding}
                        onChange={() => {
                          if (
                            userInfoData?.subscription_status === "$199 Premium"
                          ) {
                            handleChange({
                              target: {
                                name: "removeBranding",
                                value: !values.removeBranding,
                              },
                            });
                          } else {
                            navigate("/dashboard/profile");
                          }
                        }}
                      />
                    </>
                  ) : null}
                </div>
                <div className="flex gap-6 md:flex-row flex-col">
                  <Button
                    type="submit"
                    isProcessing={isSubmitting}
                    gradientDuoTone="purpleToBlue"
                    size="lg"
                  >
                    <p className="font-bold">
                      {settingsSaved
                        ? "✨ Update My Chatbot"
                        : "✨ Create My Chatbot"}
                    </p>
                  </Button>
                  {botId && (
                    <PreviewButtonLink
                      websiteURL={values.websiteURL}
                      botId={botId}
                      onClick={onPreviewClick}
                    >
                      Preview on your Website
                    </PreviewButtonLink>
                  )}
                </div>
              </form>

              <div className="space-y-2">
                <div>
                  <h5 className="text-xl font-bold">Embed Widget</h5>
                  {settingsSaved ? (
                    <p className="text-gray-500">
                      To embed the chatbot, paste the following code snippet
                      into the "head" tag of your webpage.
                    </p>
                  ) : (
                    <p className=" text-gray-500">
                      {isDemoVersion
                        ? "Create your own Chat Bot to get Widget Code! ☝️"
                        : "Save Bot Settings to get Widget Code ☝️"}
                    </p>
                  )}
                </div>

                {settingsSaved && (
                  <CopyableText
                    text={sharingScript}
                    onTextCopied={copyEmbedScript}
                  />
                )}
              </div>
            </div>
            <div className="md:absolute md:right-3 md:bottom-3 md:mb-0 mb-6 right-4 relative flex items-end justify-end">
              <Widget
                isOpen
                isForInternalUse
                settings={values}
                externalBotId={isDemoVersion && !botId ? demoBotId : botId}
              />
            </div>
          </div>
        )}
      </div>
      <Dialog
        header="Your Bot was Created! 🎉"
        visible={isBotCreatedModalVisible}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => setIsBotCreatedModalVisible(false)}
      >
        <div className="space-y-6 divide-y flex flex-col">
          <div className="space-y-4">
            <p className="text-lg font-medium">
              To embed the chatbot, paste the following code snippet into the
              "head" tag of your webpage. 👇
            </p>
            {settingsSaved && (
              <CopyableText
                text={sharingScript}
                onTextCopied={copyEmbedScript}
              />
            )}
          </div>
          <div className="w-fit self-center">
            {botId && (
              <PreviewButtonLink
                websiteURL={values.websiteURL}
                botId={botId}
                onClick={onPreviewClick}
              >
                Preview Chatbot on your Website
              </PreviewButtonLink>
            )}
          </div>
          <div className="flex gap-6 items-center justify-between md:flex-row flex-col w-full pt-6">
            <p className="text-md font-medium">
              You have 20 credits for free! To use your Chatbot on a full scale
              create an account:
            </p>
            <Button
              as={Link}
              href="/login"
              gradientDuoTone="purpleToBlue"
              size="lg"
            >
              <p className="font-bold whitespace-nowrap">Create account</p>
            </Button>
          </div>
        </div>
      </Dialog>
    </PageLayout>
  );
};
