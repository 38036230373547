class DocumentClient {
  // async getDocuments(authenticationToken) {
  //   //let formData = new FormData();
  //   //formData.append('userId', userId)

  //   let responseJson = await fetch(
  //     process.env.REACT_APP_API_URL + "/get-documents",
  //     {
  //       method: "POST",
  //       //body: formData,
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: "Bearer: " + authenticationToken,
  //       },
  //     }
  //   );

  //   let response = await responseJson.json();
  //   console.dir(response);

  //   return response.data.list.map((d) => ({
  //     fileName: d.file_name,
  //     id: d.id,
  //     fileUrl: d.file_url,
  //   }));
  // }

  // async deleteDocument(id, authenticationToken) {
  //   let formData = new FormData();
  //   formData.append("documentId", id);

  //   let responseJson = await fetch(
  //     process.env.REACT_APP_API_URL + "/delete-document",
  //     {
  //       method: "POST",
  //       body: formData,
  //       headers: {
  //         Authorization: "Bearer: " + authenticationToken,
  //       },
  //     }
  //   );

  //   let response = await responseJson.json();
  //   console.dir(response);

  //   return response.data.list.map((d) => ({
  //     fileName: d.file_name,
  //     id: d.id,
  //   }));
  // }

  async uploadDocument(file, userId, authenticationToken) {
    let formData = new FormData();
    console.log(file);
    formData.append("file", file);
    formData.append("user_id", userId);

    console.log(formData);

    let responseJson = await fetch(
      process.env.REACT_APP_API_URL + "/upload-file",
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer: " + authenticationToken,
        },
      }
    );

    let response = await responseJson.json();

    if (responseJson.status === 402) {
      throw { type: "documentLimitExceeded" };
    }

    if (response.status === 500) {
      throw new Error();
    }
    return {
      fileName: response.fileName,
      id: response.documentId,
      fileUrl: response.file_url,
    };
  }

  // async getDocumentUrl(id) {
  //   let formData = new FormData();
  //   formData.append("documentId", id);

  //   let responseJson = await fetch(
  //     process.env.REACT_APP_API_URL + "/view-document",
  //     {
  //       method: "POST",
  //       body: formData,
  //     }
  //   );

  //   let response = await responseJson.json();
  //   return response.data;
  // }
  // async getDocumentStatus(id) {
  //   let formData = new FormData();
  //   formData.append("documentId", id);

  //   let responseJson = await fetch(
  //     process.env.REACT_APP_API_URL + "/get-document-status",
  //     {
  //       method: "POST",
  //       body: formData,
  //     }
  //   );

  //   let response = await responseJson.json();
  //   return response;
  // }
}

const documentClient = new DocumentClient();
export default documentClient;
