import { createContext } from "react";

const Context = createContext({
  user: null,
  authenticationToken: null,
  botData: null,
  setBotData: () => {},

  // old
  documents: [],
  msgloading: false,
  setMsgloading: () => {},
  msg: {},
  setMsg: () => {},
  setDocuments: () => {},
  isSharing: false,
  isHidingLink: false,
  isHidingLogo: true,
  sharingTitle: "",
  setIsMaxDocumentErrorShowing: () => {},
});

export default Context;
