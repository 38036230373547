import React, { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../../../firebase";
import Illustration from "../images/auth-illustration.svg";
import ContinueWithGoogleButton from "../partials/authentication/ContinueWithGoogleButton";
import EmailPasswordForm from "../partials/authentication/EmailPasswordForm";

function SignIn() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/* Site header */}
      <header className="absolute w-full z-30">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="flex items-center justify-between h-16 md:h-20">
            {/* Site branding */}
            <div className="shrink-0 mr-4">
              {/* Logo */}
              <Link className="block" to="/" aria-label="Cruip">
                <p className="h4 font-hkgrotesk mb-6" data-aos="fade-up">
                  VivoChat
                </p>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main className="grow">
        <section className="relative">
          {/* Illustration */}
          <div
            className="hidden md:block absolute left-1/2 -translate-x-1/2 pointer-events-none -z-10"
            aria-hidden="true"
          >
            <img
              src={Illustration}
              className="max-w-none"
              width="1440"
              height="332"
              alt="Page Illustration"
            />
          </div>
          <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-24 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-3 md:pb-12">
                <h1 className="h2 text-2xl md:text-5xl font-hkgrotesk">
                  Welcome back!
                </h1>
              </div>
              <div className="max-w-sm mx-auto">
                <ContinueWithGoogleButton />
                {/* Divider */}
                <div className="flex items-center my-6">
                  <div
                    className="border-t border-slate-800 grow mr-3"
                    aria-hidden="true"
                  />
                  <div className="text-sm text-slate-500 italic">Or</div>
                  <div
                    className="border-t border-slate-800 grow ml-3"
                    aria-hidden="true"
                  />
                </div>
                {/* Social login */}
                <EmailPasswordForm mode="sign-in" />
                <div className="text-center mt-6">
                  <div className="text-sm text-slate-500">
                    Don't you have an account?{" "}
                    <Link className="font-medium text-indigo-500" to="/signup">
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default SignIn;
