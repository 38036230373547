import React, { useEffect } from "react";

import Header from "../partials/Header";
import Hero from "../partials/Hero";
import Testimonials from "../partials/Testimonials";
import Features from "../partials/Features";
import Features02 from "../partials/Features02";
import Comparision from "../partials/Comparision";
import Demo from "../partials/Demo";
import Integrations from "../partials/Integrations";
import TalkingHead from "../partials/TalkingHead";
import Pricing from "../partials/Pricing";
import SingleTestimonial from "../partials/SingleTestimonial";
import Faqs from "../partials/Faqs";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";
import HowItWorks from "../partials/HowItWorks";
import { Helmet } from "react-helmet";

function Home() {
  useEffect(() => {
    document.body.className =
      "font-inter antialiased text-slate-800 tracking-tight";
    return () => {
      document.body.className = "";
    };
  });
  return (
    <>
      <Helmet>
        <script
          async
          src="https://www.vivochat.ai/dist/widget.js"
          vivochat-bot-id="df0ea59c-a4e3-41b7-9257-1353bde1e76b"
          vivochat-bot-container=".landing-page"
        />
      </Helmet>
      <div className="flex flex-col min-h-screen overflow-hidden landing-page">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="grow">
          {/*  Page sections */}
          <Hero />

          {/* <HowItWorks /> */}
          {/* <Testimonials /> */}
          {/* <Features /> */}
          <Features02 />
          {/* <Comparision /> */}

          {/* <Demo /> */}
          {/* <Integrations /> */}

          <Pricing />
          <SingleTestimonial />

          <Faqs />
          <Cta />
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
}

export default Home;
