import dayjs from "dayjs";
import { collection, doc, getDocs } from "firebase/firestore";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import Context from "../../context";
import { db } from "../../firebase";
import mixpanel from "mixpanel-browser";

import Spinner from "../ui/Spinner";
import PageLayout from "./common/PageLayout";

export const Leads = () => {
  const { botData } = useContext(Context);
  const [leads, setLeads] = useState();
  const [loadingStatus, setLoadingStatus] = useState("initial");

  const [errorText, setErrorText] = useState(
    "There was a problem with fetching emails"
  );

  useEffect(() => {
    const getLeads = async () => {
      try {
        if (!botData?.bot_id) {
          setLoadingStatus("error");
          setErrorText(
            "You don't have bots just yet, create your first bot to see this page"
          );
        }

        setLoadingStatus("processing");
        const botRef = doc(db, "bots", botData.bot_id);
        const leadsRef = collection(botRef, "leads");
        const leadsSnapshot = await getDocs(leadsRef);
        const leadsData = [];
        leadsSnapshot?.forEach((doc) => {
          if (doc.exists()) {
            const data = {
              ...doc.data(),
              date: dayjs
                .unix(doc.data().date.seconds)
                .format("DD MMM YYYY HH:mm"),
            };
            leadsData.push(data);
          }
        });
        setLeads(leadsData);
        setLoadingStatus("success");
      } catch (error) {
        setLoadingStatus("error");
        console.error(error);
      }
    };
    getLeads();
  }, [botData]);

  useEffect(() => {
    mixpanel.track("Leads Page Visited", {
      botId: botData?.bot_id,
    });
  }, []);

  return (
    <div className="max-h-screen w-full overflow-auto pb-4 pt-[50px] lg:pt-0">
      <PageLayout
        heading="Leads"
        rightElement={
          leads?.length ? (
            <CSVLink
              className="block text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 w-fit"
              data={leads}
              headers={[
                { label: "Email", key: "email" },
                { label: "Date", key: "date" },
                { label: "Country", key: "country" },
                { label: "City", key: "city" },
              ]}
            >
              Export Leads to CSV
            </CSVLink>
          ) : undefined
        }
      >
        <div className="w-full h-full flex justify-center">
          {(loadingStatus === "processing" || loadingStatus === "initial") && (
            <Spinner />
          )}
          {loadingStatus === "error" && <p>{errorText}</p>}
          {loadingStatus === "success" && leads && (
            <div className="w-full p-4 space-y-3">
              <DataTable
                value={leads}
                sortMode="multiple"
                paginator
                rows={12}
                emptyMessage="No Leads just yet, make sure 'Ask for email' feature is turned
                on in Bot Settings Page."
                tableStyle={{ minWidth: "50rem" }}
                removableSort
              >
                <Column
                  field="email"
                  header="Email"
                  sortable
                  style={{ width: "25%" }}
                />
                <Column
                  field="country"
                  header="Country"
                  sortable
                  style={{ width: "25%" }}
                />
                <Column
                  field="city"
                  header="City"
                  sortable
                  style={{ width: "25%" }}
                />
                <Column
                  field="date"
                  header="Date"
                  sortable
                  style={{ width: "25%" }}
                />
              </DataTable>
            </div>
          )}
        </div>
      </PageLayout>
    </div>
  );
};
