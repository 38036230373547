import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";

import { useDropzone } from "react-dropzone";

import Context from "../../../../context";
import { Messages } from "primereact/messages";

import documentClient from "../../../../clients/DocumentClient";

import useFirestoreRealTimeData from "../../../../utils/hooks/useFirestoreRealTimeData";

const TrainByPDF = ({ tabActiveIndex }) => {
  const { user, authenticationToken } = useContext(Context);
  const [pdfUploadLoadingStatus, setPDFUploadLoadingStatus] =
    useState("initial");
  const msgs = useRef(null);

  const firestoreUserUpToDateData = useFirestoreRealTimeData("users", user.uid);

  useEffect(() => {
    if (!msgs?.current || !firestoreUserUpToDateData) {
      return;
    }

    if (tabActiveIndex !== 1) {
      return;
    }

    msgs.current.clear();

    if (
      !firestoreUserUpToDateData?.pdf_progress_status ||
      firestoreUserUpToDateData?.pdf_progress_status === "initial"
    ) {
      msgs.current?.show({
        sticky: true,
        severity: "info",
        summary: "Upload PDF and start training your ChatBot on it!",
        closable: false,
      });
    }

    if (firestoreUserUpToDateData?.pdf_progress_status === "processing") {
      msgs.current?.show({
        sticky: true,
        severity: "warn",
        summary: "Training ChatBot on your PDF file is In Progress.",
        detail: "It will take some time",
        closable: false,
      });
    }

    if (firestoreUserUpToDateData?.pdf_progress_status === "success") {
      msgs.current?.show({
        sticky: true,
        severity: "success",
        summary: "Training on your PDF file Successfully finished!",
        closable: false,
      });
    }

    if (firestoreUserUpToDateData?.pdf_progress_status === "error") {
      msgs.current?.show({
        sticky: true,
        severity: "error",
        summary: "There was a problem with training ChatBot on your Sitemap.",
        detail: "Check if it's setup correctly and try again.",
        closable: false,
      });
    }
  }, [msgs, tabActiveIndex, firestoreUserUpToDateData]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        documentClient.uploadDocument(
          acceptedFiles[0],
          user.uid,
          authenticationToken
        );
      }
    },
    [authenticationToken]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
    },
    disabled: firestoreUserUpToDateData?.pdf_progress_status === "processing",
    multiple: false,
  });

  const generateBorderColorClassName = () => {
    if (isDragAccept) {
      return "border-green-500";
    } else if (isDragReject) {
      return "border-red-500";
    }

    return "border-gray-500";
  };
  const borderColorClassName = generateBorderColorClassName();

  return (
    <>
      <div className="card flex w-full px-3 pb-6 min-h-[120px]">
        <Messages className="w-full" ref={msgs} />
      </div>
      <div
        {...getRootProps()}
        className={`w-full flex rounded border-2 border-dashed py-10 px-12 cursor-pointer font-medium hover:bg-gray-50 ${borderColorClassName}`}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <>
            {isDragReject ? (
              <p className="text-red-500">Only PDF format supported.</p>
            ) : (
              <p className="text-green-500">Drop the PDF file here...</p>
            )}
          </>
        ) : (
          <p>Drag and drop PDF here, or click to select file</p>
        )}
      </div>
    </>
  );
};

export default TrainByPDF;
